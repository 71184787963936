import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import useClickOutside from '../../../useClickOutside';

function SelectCreatableWithSearch({  load, options,title , post,deleteFunction,funb,setIdd,typeCss,name,previousID  }) {
    const [Loadd , setLoadd] = useState(false)
    const [selected, setSelected] = useState(title)
    const [openCreate, setopenCreate] = useState(false)
    const [id, setId] = useState('')
    const [records, setRecords] = useState([]);
    useEffect(()=>{
        setSelected(name)
    },[name])
    useEffect(() => {
        setRecords(options)
    }, [options]);
    let domNode = useClickOutside(() => {
        setopenCreate(false);
    });
    const [newCreate, setnewCreate] = useState('')
    const [activeCreate, setactiveCreate] = useState('')
    const [createView, setCreateView] = useState('choose');
    const selectedOption = (name, id) => {
        setSelected(name)
        if(previousID){
            setIdd(previousID);
        }
        setactiveCreate(id)
        setIdd(id);
    }
    const afterCreate = () => {
        console.log('afterCreate')
        let neww = newCreate
        post(neww, setCreateView, setLoadd)
    }
    const handleDelete = (id) => {
        console.log('handleDelete')
        deleteFunction(id, setLoadd)
    };
    let func = (id) => {
        setId(id)
        handleDelete(id)
    }
    function handleFilter(event) {
        const newData = options.filter(row => {
            return row.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
        })
        setRecords(newData)
    }
    const openDropDown = e => {
        e.preventDefault()
        if(funb){
            funb()
        }
        setopenCreate(true)
    }
    return (
        <div className='div_creatable' >
            <div className={typeCss ? "view_creatable1 " : 'view_creatable '} onClick={openDropDown} >
                <span>{selected}</span>
                <i className="las la-angle-double-down"></i>
            </div>
            {
                openCreate && (
                    <div className='select_creatable' ref={domNode} >
                        <input className='input_custm_select' placeholder="Search ..." onChange={handleFilter} />
                        {
                            load == false ? (
                                records.map((cc) => {
                                    return (
                                        <div className={`choose_creatable ${activeCreate === cc.id ? "active_creatable" : ""} `} key={cc.id} onClick={() => selectedOption(cc.name, cc.id)} >
                                            <span>{cc.name}</span>
                                            {
                                                Loadd ? (
                                                    id === cc.id ?
                                                        <ReactLoading type="spinningBubbles" color="white" height={'4%'} width={'4%'} /> :
                                                        <i className="las la-window-close" onClick={() => func(cc.id)} ></i>) :
                                                    <i className="las la-window-close" onClick={() => func(cc.id)} ></i>
                                            }
                                        </div>
                                    )
                                })) : <div className='loadinselect'><ReactLoading type="spinningBubbles" color="#9699c2" height={'7%'} width={'7%'} /></div>
                        }
                        {
                            createView === 'choose' ? (
                                <div className="choose_creatable" onClick={() => setCreateView('create')}>
                                    <span>Create New</span>
                                    <i className="las la-plus-square"></i>
                                </div>
                            ) : (
                                <div className='choose_creatable'>
                                    <input onChange={(e) => setnewCreate(e.target.value)} />
                                    {
                                        Loadd ? <ReactLoading type="spinningBubbles" color="#9699c2" height={'4%'} width={'4%'} /> :
                                            <i className="las la-check-circle" onClick={afterCreate} ></i>
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

export default SelectCreatableWithSearch;