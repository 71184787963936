import React, { useState, useEffect } from 'react'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Geocode from 'react-geocode';
import GoogleMapReact from "google-map-react";
import {putTargetGroup } from '../../Redux/Actions/ActionTargetGroup';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { VariableTargetGroup } from './VariableTargetGroup';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';

Geocode.setApiKey("AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0");
const AnyReactComponent = () => <div><i className='fa fa-map-marker map_marker' ></i></div>;

function EditTarget({ isFilter, setisEdit, currentPage, itemsPerPage, arr, open, setOpen }) {
    let { dispatch, langmiamed } = AbstructVar()
    const { translate } = AbstructLang()
    const { state, setState, handleChangeTargetGroup, setLoad } = VariableTargetGroup()
    const { cities, loadingCities, specializations, loadingspecializations, target_types, loadingtarget_type, classifications, loadingclassifications } = useSelectorCustom()
    const { LoadCitiesCustom, LoadSpecializationsCustom, LoadTargetTypeCustom, LoadClassificationsCustom } = FunctionsLoading()
    const [location, setLocation] = useState({ map_lat: arr.lat, map_lng: arr.lng });
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            name: arr.name,
            description: arr.description,
            address: arr.address,
            fixed_phone: arr.fixed_phone,
            phone_number: arr.phone_number,
            opening_time: arr.opening_time.replace(/\s*([AaPp][Mm])\s*/, ''),
            closing_time: arr.closing_time.replace(/\s*([AaPp][Mm])\s*/, ''),
            medical_rep_name: arr.medical_rep_name,
            city_id: arr.city_id,
            city_name: arr.city_name,
            specialization_id: arr.specialization_id,
            specialization_name: arr.specialization_name,
            target_type_id: arr.target_type_id,
            target_type_name: arr.target_type_name,
            classification_id: arr.classification_id,
            classification_name: arr.classification_name,
        }));
    }, [])
    const FunSubmit = (e) => {
        e.preventDefault()
        console.log('name ' + state.name)
        console.log('description ' + state.description)
        console.log('address ' + state.address)
        console.log('fixed_phone ' + state.fixed_phone)
        console.log('opening_time ' + state.opening_time)
        console.log('closing_time ' + state.closing_time)
        console.log('location.map_lat ' + location.map_lat)
        console.log('location.map_lng ' + location.map_lng)
        console.log('phone_number ' + state.phone_number)
        console.log('city_id ' + state.city_id)
        console.log('city_name ' + state.city_name)
        console.log('specilization ' + state.specialization_id)
        console.log('specilization ' + state.specialization_name)
        console.log('classification' + state.classification_id)
        console.log('classification' + state.classification_name)
        console.log('type ' + state.target_type_id)
        console.log('type ' + state.target_type_name)
        console.log('medical_rep_name ' + state.medical_rep_name)
        dispatch(putTargetGroup(isFilter,setisEdit,currentPage,itemsPerPage,arr.id, state.name, state.description, state.address, location.map_lat, location.map_lng, state.fixed_phone, state.phone_number, state.opening_time, state.closing_time,
            state.city_id, state.specialization_id, state.classification_id, state.target_type_id, state.medical_rep_name, setLoad))
    }

    const handleMapClick = (event) => {
        const { lat, lng } = event;
        setLocation({ map_lat: lat, map_lng: lng });

        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                console.log(address);
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const handleApiLoaded = (map, maps) => {
        map.setOptions({ scrollwheel: false });
    }

    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center={false}
            classNames={{
                modal: "popAdd",
            }}
            focusTrapped={false}
        >
            <div className='popAddForm' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                <h3 className={langmiamed == 'ar' ? "textRight margin_top_20" : ""}>{translate('editTarget')}</h3>
                <form>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-pencil-alt' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('name')}</span>
                            </div>
                            <input defaultValue={state.name} type="text" onChange={(e) => handleChangeTargetGroup(e.target.value, 'name')} />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-pencil-alt' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('description')}</span>
                            </div>
                            <input defaultValue={state.description} type="text" onChange={(e) => handleChangeTargetGroup(e.target.value, 'description')} />
                        </div>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-map-marker' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('address')}</span>
                            </div>
                            <input defaultValue={state.address} onChange={(e) => handleChangeTargetGroup(e.target.value, 'address')} />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-fax' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('fixed_num')}</span>
                            </div>
                            <input defaultValue={state.fixed_phone} type='text' onChange={(e) => handleChangeTargetGroup(e.target.value, 'fixed_phone')} />
                        </div>
                    </div>
                    <div className='div_map' >
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0" }}
                            center={{ lat: location.map_lat, lng: location.map_lng }}
                            defaultZoom={12}
                            onClick={handleMapClick}
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            fullscreenControl={false}
                        >
                            <AnyReactComponent
                                lat={location.lat}
                                lng={location.lng}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-clock' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('opening_time')}</span>
                            </div>
                            <input defaultValue={state.opening_time} type="time" onChange={(e) => handleChangeTargetGroup(e.target.value, 'opening_time')} />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-clock' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('closing_time')}</span>
                            </div>
                            <input defaultValue={state.closing_time} type="time" onChange={(e) => handleChangeTargetGroup(e.target.value, 'closing_time')} />
                        </div>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-city' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('cities')}</span>
                            </div>
                            <SelectWithSearch
                                load={loadingCities}
                                title={translate('cities')}
                                array={cities}
                                setId={(value) => handleChangeTargetGroup(value, 'city_id')}
                                funb={LoadCitiesCustom}
                                name={state.city_name}
                                previousID={state.city_id}
                            />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-hand-pointer' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('specializations')}</span>
                            </div>
                            <SelectWithSearch
                                load={loadingspecializations}
                                title={translate('specialization')}
                                array={specializations}
                                setId={(value) => handleChangeTargetGroup(value, 'specialization_id')}
                                funb={LoadSpecializationsCustom}
                                name={state.specialization_name}
                                previousID={state.specialization_id}
                            />
                        </div>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-hand-pointer' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('target_type')}</span>
                            </div>
                            <SelectWithSearch
                                load={loadingtarget_type}
                                title={translate('target_type')}
                                array={target_types}
                                setId={(value) => handleChangeTargetGroup(value, 'target_type_id')}
                                funb={LoadTargetTypeCustom}
                                name={state.target_type_name}
                                previousID={state.target_type_id}
                            />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-hand-pointer' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('classifications')}</span>
                            </div>
                            <SelectWithSearch
                                load={loadingclassifications}
                                title={translate('classifications')}
                                array={classifications}
                                setId={(value) => handleChangeTargetGroup(value, 'classification_id')}
                                funb={LoadClassificationsCustom}
                                name={state.classification_name}
                                previousID={state.classification_id}
                            />
                        </div>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-user' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('delegates')}</span>
                                <span className='require' >*</span>
                            </div>
                            <input defaultValue={state.medical_rep_name}  onChange={(e) => handleChangeTargetGroup(e.target.value, 'medical_rep_name')} />
                        </div>
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-phone' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('phone')}</span>
                            </div>
                            <input defaultValue={state.phone_number} type='text' onChange={(e) => handleChangeTargetGroup(e.target.value, 'phone_number')}  />
                        </div>
                    </div>
                </form>
                <div className='div_btn' ><button onClick={FunSubmit} >
                    {
                        state.Load ? "Loading ... " :
                            <>{translate('edit')}</>
                    }
                </button></div>
            </div>
        </RModal>
    )
}

export default EditTarget