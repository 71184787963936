import React, { useEffect, useState } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import noint from '../../../images/icons/noInternet.png'

function NoInternet({open, setOpen}) {
    useEffect(()=>{
        console.log("internet")
    })
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center
            classNames={{
                modal: "popupWarning",
            }}
            focusTrapped={false}
        >
            <div className="warning">
               <img src={noint} />
               <span>No Internet</span>
               <span>Please Reload</span>
            </div>
        </RModal>
    )
}

export default NoInternet;