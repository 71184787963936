import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const getWorkPlanPro = (WorkPlanPro) => {
    return ({
        type: types.GET_WORK_PLAN_PRODUCTS,
        payload: WorkPlanPro
    })
}
const getUnassignPro = (Unassign_product) => {
    return ({
        type: types.GET_UNASSIGN_PRODUCTS,
        payload: Unassign_product
    })
}

const unassignWorkPlanProo = () => {
    return ({
        type: types.UNASSIGN_WORK_PLAN_PRODUCTS
    })
}

const assignWorkPlanProo = () => {
    return ({
        type: types.ASSIGN_WORK_PLAN_PRODUCTS
    })
}
const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}



export const loadWorkPlanPro = (id,navigate,setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/work-plan/${id}/products`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getWorkPlanPro(res.data.data))
        }).catch((err) => {
            console.log(err)
            hasRout(err.response.status,navigate)
            if(err.message == "Network Error" || err.message){
                setopenInternet(true)
            }
        })
    }
}
export const loadUnassignPro = (id) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/work-plan/${id}/unassign-products`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getUnassignPro(res.data.data))
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const unassignWorkPlanPro = (id,products,navigate,setopenInternet,setLoad) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        console.log(products)
        setLoad(true)
        axios.post(`${base_url}api/work-plan/${id}/unassign-product`,{
            products
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(unassignWorkPlanProo())
            dispatch(loadWorkPlanPro(id,navigate,setopenInternet))
            dispatch(loadUnassignPro(id))
            setLoad(false)
        }).catch((err) => {
            console.log(err)
            setLoad(false)
        })
    }
}

export const assignWorkPlanPro = (id,products,navigate,setopenInternet,setLoad) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        console.log(products)
        setLoad(true)
        axios.post(`${base_url}api/work-plan/${id}/assign-product`,{
            products
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(unassignWorkPlanProo())
            dispatch(loadWorkPlanPro(id,navigate,setopenInternet))
            dispatch(loadUnassignPro(id))
            setLoad(false)
        }).catch((err) => {
            console.log(err)
            setLoad(false)
        })
    }
}



export const emptyWorkPlanPro = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_WORK_PLAN_PRODUCTS ))
    }
}

export const emptyUnassignPro = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_UNASSIGN_PRODUCTS ))
    }
}

