import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Loader from "./Components/General/Loader";
import AllLayout from "./Components/General/AllLayout";
import useRout from "./Components/General/Routes";

const App = () => {
  let token = localStorage.getItem("tokenMiamed");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, []);

  const routes = useRout(token);
  return (
    <DndProvider backend={HTML5Backend}>
      <body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {token != null && <AllLayout />}
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </>
        )}
      </body>
    </DndProvider>
  );
};

export default App;
