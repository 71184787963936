import React, { useState } from "react";
import useClickOutside from "../../../useClickOutside";
import ReactLoading from "react-loading";
import { CheckFont } from "./CheckLang";
import { useTranslation } from "react-i18next";

function MultiSelectCheckbox({
  testarr,
  selectedOptions,
  setSelectedOptions,
  typeCss,
  funb,
  load,
  is_width,
}) {
  let { t } = useTranslation();
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [open, setopen] = useState(false);
  const toggleOption = (optionId, optionName) => {
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(optionId)) {
        return prevOptions.filter((opt) => opt !== optionId);
      } else {
        return [...prevOptions, optionId];
      }
    });
    setSelectedOptionsName((prevOptions) => {
      if (prevOptions.includes(optionName)) {
        return prevOptions.filter((opt) => opt !== optionName);
      } else {
        return [...prevOptions, optionName];
      }
    });
  };
  let domNode = useClickOutside(() => {
    setopen(false);
  });
  const openDropDown = (e) => {
    e.preventDefault();
    funb();
    setopen(true);
  };

  return (
    <>
      <div
        className="div_creatable nomarginLeft"
        style={is_width ? { width: "100%" } : { width: "550px" }}
      >
        <div
          className={typeCss ? "view_creatable1 " : "view_creatable "}
          onClick={openDropDown}
        >
          {selectedOptionsName.length !== 0 ? (
            <>
              {selectedOptionsName.map((e) => {
                return <span>{e}</span>;
              })}
            </>
          ) : (
            <span>{t("Select a delegate")}</span>
          )}
          <i className="las la-angle-double-down icon_searchselect"></i>
        </div>
        
        {open && (
          <div className="select_creatable" ref={domNode}>
            {load === false ? (
              testarr.map((option) => (
                <div key={option.id} className="lable_multi">
                  <input
                    type="checkbox"
                    value={option.id}
                    checked={selectedOptions.includes(option.id)}
                    onChange={(e) => toggleOption(option.id, option.name)}
                  />
                  <span style={{ fontFamily: CheckFont(option.name) }}>
                    {option.name}
                  </span>
                </div>
              ))
            ) : (
              <div className="loadinselect">
                <ReactLoading
                  type="spinningBubbles"
                  color="#9699c2"
                  height={"7%"}
                  width={"7%"}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default MultiSelectCheckbox;
