import { useState } from "react"

export const VariableTargetGroup =() => {
    const [state, setState] = useState({
        search_value: '',
        Load: false,
        city_id: "",
        city_name: "",
        specialization_id :"",
        specialization_name :"",
        target_type_id: "",
        target_type_name: "",
        name: "",
        description: "",
        address: "",
        fixed_phone: "",
        phone_number: "",
        opening_time: "",
        closing_time: "",
        medical_rep_name: "",
        classification_id: "",
        classification_name: "",
        openMap: false,
        isFilter: '',
        isEdit: '',
        isDelete : "",
        lat: null,
        lng: null,
        open: false,
        openExcel: false,
        openEdit: false,
        arr1: [],
        created_at: ''
    })
    const setLoad = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setOpen = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            open: value,
        }));
    };
    const setopenEdit = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            openEdit: value,
        }));
    };
    const setisDelete = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            isDelete: value,
        }));
    };
    const setisEdit = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            isEdit: value,
        }));
    };
    const setOpenMap = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            openMap: value,
        }));
    };
    const setopenExcel = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            openExcel: value,
        }));
    };
    const setisFilter = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            isFilter: value,
        }));
    };
    const handleChangeTargetGroup = (value, variableName) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const handleEditTargetGroup = (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            name: arr.name,
            description: arr.description,
            type: arr.type,
            category: arr.category,
            address: arr.address,
            check_in: arr.check_in,
            check_out: arr.check_out,
            lat: arr.lat,
            lng: arr.lng,
            target_type_id: arr.target_type_id,
            target_type_name: arr.target_type_name,
            specialization_id: arr.specialization_id,
            specialization_name: arr.specialization_name,
            classification_id: arr.classification_id,
            classification_name: arr.classification_name,
            opening_time: arr.opening_time,
            closing_time: arr.closing_time,
            city_name: arr.city_name,
            city_id: arr.city_id,
            fixed_phone: arr.fixed_phone,
            medical_rep_name: arr.medical_rep_name,
            phone_number: arr.phone_number
        };
        setState((prevState) => ({
            ...prevState,
            arr1: formValues,
            openEdit: true
        }));
    }
    const openAddNew = () => {
        setState((prevState) => ({
            ...prevState,
            open: true
        }));
    }
    const openMapp = (latt, lngg) => {
        setState((prevState) => ({
            ...prevState,
            openMap: true,
            lat: latt,
            lng: lngg
        }));
    }
  return {
    ...state,
    state,
    setState,
    handleChangeTargetGroup,
    setLoad,
    handleEditTargetGroup,
    openAddNew,
    openMapp,
    setOpen,
    setisDelete,
    setisEdit,
    setOpenMap,
    setopenExcel,
    setisFilter,
    setopenEdit
  }
}
