import React, { useState, useEffect } from "react";
import DataTable, { Columns, Rows, Pagination } from "react-data-table-component";
import './Table2.css'
import NoData from "../General/NoData/NoData";

function Table2({ border,col, roo }) {
  const handleSelectedRowsChange = () => {
    console.log("nour")
  }
  const customStyles = {
    table: {
      style: {
        backgroundColor: `white`
      }
    },
    rows: {
      style: {
        backgroundColor: `white`,
        color: `gray`,
        marginTop: "10px",
        marginBottom: "10px"
      }
    },
    headCells: {
      style: {
        backgroundColor: `white`,
        color: "#606280",
        fontSize: "13px",
        backgroundColor: "white",
        
      }
    },
    pagination: {
      style: {
        backgroundColor: `white`,
        color: `var(--title-color)`,
        borderTop: "1px solid white"
      }
    },
  };

  return (
    <div className="table2" >
      <DataTable
        columns={col}
        data={roo}
        pagination
        onSelectedRowsChange={handleSelectedRowsChange}
        customStyles={customStyles}
        noDataComponent={<NoData />}
        className={border ? "custom-table-border" : "custom-table" }
        noHeader
        paginationPerPage={9}
      />
    </div>
  );
}

export default Table2;