import React, {useEffect } from 'react'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch } from "react-redux";
import { deleteProductCat, loadProductCat, postProductCat } from '../../../Redux/Actions/ActionConfigProduct';
import EditProductCat from './EditProductCat';
import InnerLoader from '../../General/InnerLoader';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';
import { VariableProductCategory } from './VariableProductCategory';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function Product_category({ open, setOpen, langmiamed, t }) {
    let dispatch = useDispatch();
    const {productCat,loadingproductCat} = useSelectorCustom()
    const {state, handleChangeProductCategory,handleEditProductCategory,setLoad,setLoadCat,setIDCat,setproduct_cat_id} = VariableProductCategory()
    useEffect(() => {
        dispatch(loadProductCat())
    }, [])
    const handleDelete = (id) => {
        setIDCat(id)
        dispatch(deleteProductCat(id, setLoadCat))
    };
    const FunSend = (e) => {
        e.preventDefault()
        dispatch(postProductCat(state.name, state.font_family, setLoad))
    }
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center
            classNames={{
                modal: "proCatPop",
            }}
            focusTrapped={false}
        >
            <div className='div_proCatPop' dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
                <h3 style={{fontFamily: CheckFont(t('product_cat'))}} className={langmiamed == 'ar' ? "textRight" : ""} >{t('product_cat')}</h3>
                <div className='form_with_info' >
                    <form className='form_proCat' >
                        <input style={{fontFamily: CheckFont(t('product_cat'))}} placeholder={t('product_cat')} type="text" onChange={(e) => handleChangeProductCategory(e.target.value, 'name')} />
                        <select style={{fontFamily: CheckFont(t('font_family'))}} onChange={(e) => handleChangeProductCategory(e.target.value, 'font_family')}>
                            <option disabled="disabled" selected="selected">{t('font_family')}</option>
                            <option value='AMBOULES' >AMBOULES</option>
                            <option value='EYEDROPS' >EYE DROPS</option>
                            <option value='IVS' >IVS</option>
                            <option value='NASALDROPS' >NASAL DROPS</option>
                        </select>
                        <button style={{fontFamily: CheckFont(t('save'))}} onClick={FunSend} >
                            {
                                state.Load ? "Loading ..." : `${t('save')}`
                            }
                        </button>
                    </form>
                    <div className='info_proCat' >
                        {
                            loadingproductCat == false ? (
                            productCat.map((pp) => {
                                return (
                                    <>
                                        {
                                            state.product_cat_id === pp.id ? (
                                                <EditProductCat t={t} langmiamed={langmiamed} arr={state.arr1} setproduct_cat_id={setproduct_cat_id} />
                                            ) :
                                                <div className='one_info_proCat' key={pp.id} dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
                                                    <div className='div_info_proCat' >
                                                        <span style={{fontFamily: CheckFont(pp.name)}} className={langmiamed == 'ar' ? 'name_proCat textRight' : "name_proCat"} >{pp.name}</span>
                                                        <span style={{fontFamily: CheckFont(pp.font_family)}} className={langmiamed == 'ar' ? 'fontFamily textRight' : "fontFamily"}>{pp.font_family}</span>
                                                    </div>
                                                    <div className='action_proCat' >
                                                        <i className="las la-pen edit_proCat" onClick={(event) => handleEditProductCategory(event, pp)}  ></i>
                                                        {
                                                            state.LoadCat ? (
                                                                state.IDCat === pp.id ?
                                                                    <i className='las la-spinner icon_edit_table' ></i>
                                                                    : <i class="las la-times-circle  del_proCat" onClick={() => handleDelete(pp.id)} ></i>
                                                            ) : <i class="las la-times-circle  del_proCat" onClick={() => handleDelete(pp.id)} ></i>
                                                        }

                                                    </div>
                                                </div>
                                        }

                                    </>
                                )
                            })):<div className='div_loadd' style={{height: '20vh'}} ><InnerLoader /></div>
                        }
                    </div>
                </div>
            </div>
        </RModal>
    )
}

export default Product_category