import React, { useEffect, useState } from "react";
import Page_Header from "../General/Page_Header";
import { array_action } from "../../dummyData";
import ActionTop from "../General/ActionTop";
import "./Products.css";
import AddProduct from "./AddProduct";
import FilterProduct from "./FilterProduct";
import { deleteProduct, deleteTool, loadProduct } from "../../Redux/Actions/ActionProduct";
import Warning from "../General/Warning_Delete/Warning";
import InnerLoader from "../General/InnerLoader";
import EditPro from "./EditPro";
import { exportFile } from "../General/Notification";
import SinglePagenation from "../General/Pagination/SinglePagenation";
import NoData from "../General/NoData/NoData";
import NoInternet from "../General/NotPermission/NoInternet";
import { PagenationCustom } from "../General/GeneralComponent/PagenationCustom";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { DeleteComponent } from "../General/GeneralComponent/DeleteComponent";
import { VariableProducts } from "./VariableProducts";
import OneProduct from "./OneProduct";
import AddMarketingTool from "./AddMarketingTool";

function Products() {
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const { product, loadingproduct } = useSelectorCustom();
  const { translate } = AbstructLang();
  const reversedProduct = [...product].reverse();
  const { state, setOpen, setOpenMar, setopenEdit, handleEditProduct } =
    VariableProducts();
  const { stateD, warning, setdeletee, setloadDel } = DeleteComponent();
  const handleDelete = (id ,type) => {
    if(type ==="medicine_samples"){

      dispatch(deleteProduct(id, setdeletee, setloadDel));
    }else if (type ==="marketing_tools"){
      dispatch(deleteTool(id, setdeletee, setloadDel));

    }
  };
  const openAddNew = () => {
    setOpen(true);
  };
  const openAddNewMar = () => {
    setOpenMar(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadProduct(navigate, setopenInternet));
  }, []);
  const exportToExcel = () => {
    exportFile(product, "product_data");
  };
  const { displayArr, pageCount, changePage } = PagenationCustom(
    reversedProduct,
    12
  );
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("products")}
            path={`${translate("home")} / ${translate("products")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            is_product={true}
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
            FunctionCustum={openAddNew}
            FunctionCustumMar={openAddNewMar}
            is_view={true}
            isMarketingTools={true}
            
            
          />
          {state.open && (
            <AddProduct
              t={translate}
              langmiamed={langmiamed}
              open={state.open}
              setOpen={setOpen}
            />
          )}
          {state.openMar && (
            <AddMarketingTool
              t={translate}
              langmiamed={langmiamed}
              open={state.openMar}
              setOpen={setOpenMar}
            />
          )}
          <FilterProduct
            navigate={navigate}
            setopenInternet={setopenInternet}
            t={translate}
            langmiamed={langmiamed}
          />
          <div className="allproducts" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingproduct == false ? (
              displayArr.map((pp) => {
                return (
                  <>
                    {pp.category_font_family === "AMBOULES" ? (
                      // pp.category_font_family 
                      <OneProduct
                        pp={pp}
                        langmiamed={langmiamed}
                        handleEditProduct={handleEditProduct}
                        warning={warning}
                        font_family="AMBOULES"
                      />
                    ) :  pp.category_font_family === "EYEDROPS" ? (
                      // pp.category_font_family
                      <OneProduct
                        pp={pp}
                        langmiamed={langmiamed}
                        handleEditProduct={handleEditProduct}
                        warning={warning}
                        font_family="EYE DROPS"
                      />
                    ) :  pp.category_font_family === "IVS"? (
                      // pp.category_font_family === "IVS"
                      <OneProduct
                        pp={pp}
                        langmiamed={langmiamed}
                        handleEditProduct={handleEditProduct}
                        warning={warning}
                        font_family="IVS"
                      />
                    ) : pp.category_font_family === "NASALDROPS"  ? (
                      // pp.category_font_family === "NASALDROPS" 
                      <OneProduct
                        pp={pp}
                        langmiamed={langmiamed}
                        handleEditProduct={handleEditProduct}
                        warning={warning}
                        font_family="NASAL DROPS"
                      />
                    ) : (
                      <OneProduct
                      pp={pp}
                      langmiamed={langmiamed}
                      handleEditProduct={handleEditProduct}
                      warning={warning}
                      font_family="marketing_tools"
                    />
                    )}
                  </>
                );
              })
            ) : (
              <div className="div_loadd">
                <InnerLoader />
              </div>
            )}
            {loadingproduct == false && product.length == 0 && <NoData />}
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
          </div>
        </div>
      </div>
      {stateD.deletee == true ? (
        <Warning
          loadDel={stateD.loadDel}
          arr={stateD.arrW}
          open={stateD.deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {state.openEdit && (
        <EditPro arr={state.arr1} open={state.openEdit} setOpen={setopenEdit} />
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default Products;
