import React, { useState, useEffect } from "react";
import useClickOutside from "../../useClickOutside";
import Add from "../General/Add";
import { useDispatch } from "react-redux";
import { postWorkFlow } from "../../Redux/Actions/ActionWorkFlow";
import Switch from "react-switch";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { VariableWorkFlow } from "./VariableWorkFlow";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";

function AddWorkFlow({ langmiamed, setOpen, t }) {
  let dispatch = useDispatch();
  const { state, handleChangeVariableWorkFlow, setstatus, setLoad } =
    VariableWorkFlow();
  const { cities, loadingCities } = useSelectorCustom();
  const { LoadCitiesCustom } = FunctionsLoading();
  const submit = (event) => {
    event.preventDefault();
    dispatch(
      postWorkFlow(
        state.name,
        state.description,
        state.status,
        state.city_id,
        setLoad,
        setOpen
      )
    );
  };
  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  const activee = () => {
    const newValue = state.status === "0" ? "1" : "0";
    setstatus(newValue);
  };
  return (
    <div
      className={`add_target_group ${
        langmiamed == "ar" ? "add_target_group_ar" : "add_target_group_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <Add
        langmiamed={langmiamed}
        icon="las la-plus-circle"
        title={`${t("addFlow")}`}
      />
      <form className="general_form">
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("name")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="text"
          onChange={(e) => handleChangeVariableWorkFlow(e.target.value, "name")}
        />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("description")}</span>
          <span className="require">*</span>
        </div>
        <textarea
          onChange={(e) =>
            handleChangeVariableWorkFlow(e.target.value, "description")
          }
        />
        <div className="form_header">
          <i
            className={`las la-city ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("cities")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingCities}
          title={t("cities")}
          array={cities}
          setId={(value) => handleChangeVariableWorkFlow(value, "city_id")}
          funb={LoadCitiesCustom}
        />
        <div className="form_header">
          <i
            className={`las la-comment ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("in_service")}</span>
          <span className="require">*</span>
        </div>
        <Switch
          className="swith_add"
          onColor="#4285C5"
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={40}
          handleDiameter={15}
          checked={state.status === "1"}
          onChange={activee}
        />
        <br></br>
        <button onClick={submit}>
          {state.Load ? "Loading ..." : `${t("save")}`}
        </button>
      </form>
    </div>
  );
}

export default AddWorkFlow;
