import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const getVisites = (external_visites) => {
    return ({
        type: types.GET_EXTERNAL_VISITES,
        payload: external_visites
    })
}


export const loadVisites = (navigate,setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/external-visits`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getVisites(res.data.data))
        }).catch((err) => {
            console.log(err)
            hasRout(err.response.status,navigate)
            if (err.message == "Network Error" || err.message) {
                setopenInternet(true);
            }
        })
    }
}

export const loadFilterVisites = (city_id, medical_rep_id, setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/external-visits?filter[medical_rep_id]=${medical_rep_id}&filter[city_id]=${city_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getVisites(res.data.data))
            setLoad(false)
        }).catch((err) => {
            console.log(err)
            setLoad(false)
        })
    }
}

