import React,{useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { putCity } from '../../../Redux/Actions/ActionCountries';
import useClickOutside from '../../../useClickOutside';

function EditCity({ country_id1,arr, setcity_id,setloadcityy }) {
    let dispatch = useDispatch();
    const [name , setName] = useState(arr.name)
    const [Load , setLoad] = useState(false)
    const post = () => {
        console.log(arr)
        dispatch(putCity(country_id1,arr.id , name , arr.country_id,setLoad,setcity_id,setloadcityy))
        
    }
    let domNode = useClickOutside(() => {
        setcity_id(null);
    });
    return (
        <div className='onecity'  ref={domNode} >
            <input className='editCity' defaultValue={name} onChange={(e)=> setName(e.target.value)} />
            <div>
                <i className={ Load ? "las la-spinner icon_edit_table": "las la-save icon_edit_table"} onClick={post}></i>
            </div>
        </div>
    )
}

export default EditCity