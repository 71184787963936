import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadProduct } from '../../../Redux/Actions/ActionProduct';
import InnerLoader from '../../General/InnerLoader';
import useClickOutside from '../../../useClickOutside';
import { assignWorkPlanPro, emptyUnassignPro, loadUnassignPro } from '../../../Redux/Actions/ActionWorkPlanProduct';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function MultiSelectStock({ work_plan_id, t, langmiamed, setOpen }) {
    const [openInternet, setopenInternet] = useState(false);
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [Load, setLoad] = useState(false);
    const [arrID, setarrID] = useState([]);
    const [showQuantity, setShowQuantity] = useState({});

    const Unassign_product = useSelector(state => state.Product_WorkPlan.Unassign_product)
    const loadingUnassign_product = useSelector(state => state.Product_WorkPlan.loadingUnassign_product)

    useEffect(() => {
        dispatch(loadUnassignPro(work_plan_id));
        return (() => {
            dispatch(emptyUnassignPro())
        })
    }, []);

    const handleCheckboxChange = (productId) => {
        const isProductSelected = arrID.some((item) => item.id === productId);

        if (isProductSelected) {
            setarrID((prevArr) => prevArr.filter((item) => item.id !== productId));
            setShowQuantity((prevShowQuantity) => ({
                ...prevShowQuantity,
                [productId]: false,
            }));
        } else {
            setarrID((prevArr) => [...prevArr, { id: productId, quantity: 0 }]);
            setShowQuantity((prevShowQuantity) => ({
                ...prevShowQuantity,
                [productId]: true,
            }));
        }
    };

    const handleQuantityChange = (productId, newQuantity) => {
        setarrID((prevArr) =>
            prevArr.map((item) =>
                item.id === productId ? { ...item, quantity: newQuantity } : item
            )
        );
    };

    let domNode = useClickOutside(() => {
        setOpen(false);
    });
    const print = e => {
        e.preventDefault()
        dispatch(assignWorkPlanPro(work_plan_id, arrID, navigate, setopenInternet, setLoad))
    }
    return (
        <div
            className={`div_assign_stock ${langmiamed == 'ar' ? 'div_assign_stock_ar' : 'div_assign_stock_en'
                } `}
            ref={domNode}
            dir={langmiamed == 'ar' ? 'rtl' : 'ltr'}
        >
            {loadingUnassign_product == false ? (
                Unassign_product.map((pp) => {
                    return (
                        <>
                            <div className='div_assign_stock_row' key={pp.id}>
                                <input
                                    type='checkbox'
                                    onChange={() => handleCheckboxChange(pp.id)}
                                />
                                <span style={{ color: pp.color || 'black', fontFamily: CheckFont(pp.name) }}>{pp.name}</span>
                            </div>
                            {showQuantity[pp.id] && ( // عرض حقل الكمية فقط إذا كانت الحالة مُفعلة
                                <input
                                    type='number'
                                    className='quantity_stock'
                                    placeholder='quantity'
                                    onChange={(e) =>
                                        handleQuantityChange(pp.id, parseInt(e.target.value, 10))
                                    }
                                />

                            )}</>
                    );
                })
            ) : (
                <div className='div_loadd'>
                    <InnerLoader />
                </div>
            )}
            <button onClick={print} >{Load ? 'Loading ...' : `${t('save')}`}</button>
        </div>
    );
}

export default MultiSelectStock;