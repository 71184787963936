import React, { useState, useEffect } from "react";
import Page_Header from "../General/Page_Header";
import { array_action } from "../../dummyData";
import ActionTop from "../General/ActionTop";
import "./Delegates.css";
import AddDelegates from "./AddDelegates";
import FilterDelegates from "./FilterDelegates";
import {
  deleteMedicalRep,
  loadMedicalRep,
} from "../../Redux/Actions/ActionMedicalRep";
import InnerLoader from "../General/InnerLoader";
import Warning from "../General/Warning_Delete/Warning";
import EditMedicalRep from "./EditMedicalRep";
import SinglePagenation from "../General/Pagination/SinglePagenation";
import { exportFile } from "../General/Notification";
import userr from "../../images/icons/user_mov.png";
import city from "../../images/icons/building-silhouette.png";
import loc_icon from "../../images/icons/location_icon.svg";
import phone from "../../images/icons/id.png";
import NoData from "../General/NoData/NoData";
import NoInternet from "../General/NotPermission/NoInternet";
import people from "../../images/icon_sidebar/people.png";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { DeleteComponent } from "../General/GeneralComponent/DeleteComponent";
import { PagenationCustom } from "../General/GeneralComponent/PagenationCustom";
import { VariableDelegates } from "./VariableDelegates";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

function Delegates() {
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const { medicalRep, loadingmedicalRep } = useSelectorCustom();
  const { translate } = AbstructLang();
  const reversedMedicalRep = [...medicalRep].reverse();
  const { stateD, warning, setdeletee, setloadDel } = DeleteComponent();
  const { state, handleEditClick, setopenEdit, setOpen } = VariableDelegates();
  const { displayArr, pageCount, changePage } = PagenationCustom(
    reversedMedicalRep,
    12
  );
  const handleDelete = (id) => {
    dispatch(deleteMedicalRep(id, setdeletee, setloadDel));
  };
  const openAddNew = () => {
    setOpen(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadMedicalRep(navigate, setopenInternet));
  }, []);

  const exportToExcel = () => {
    exportFile(reversedMedicalRep, "medical_Rep_data");
  };
  const goToVisits = (id, name) => {
    navigate(`/medical-representative/${id}/${name}`);
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("delegates")}
            path={`${translate("home")} / ${translate("delegates")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
            FunctionCustum={openAddNew}
            is_view={true}
          />
          {state.open && (
            <AddDelegates
              t={translate}
              langmiamed={langmiamed}
              open={state.open}
              setOpen={setOpen}
            />
          )}
          <FilterDelegates
            navigate={navigate}
            setopenInternet={setopenInternet}
            langmiamed={langmiamed}
            t={translate}
          />
          <div className="allDelegate" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingmedicalRep == false ? (
              displayArr.map((dd) => {
                return (
                  <div
                    className={`card1 ${
                      langmiamed == "ar" ? "card1_ar" : "card1_en"
                    } `}
                  >
                    <span
                      className={`medwithvisits ${
                        langmiamed === "ar"
                          ? "medwithvisits_ar"
                          : "medwithvisits_en"
                      }`}
                      onClick={() => goToVisits(dd.id, dd.name)}
                    >
                      visits
                    </span>
                    <i
                      className={`fas fa-user-circle dele_icon ${
                        langmiamed == "ar" ? "margin_right_20" : ""
                      }`}
                    ></i>
                    <div className="infos">
                      <div
                        className={`name ${
                          langmiamed == "ar" ? "textRight margin_right_20" : ""
                        }`}
                      >
                        <h2 style={{ fontFamily: CheckFont(dd.first_name) }}>
                          {dd.first_name} {dd.last_name}
                        </h2>
                        <h4 style={{ fontFamily: CheckFont(dd.email) }}>
                          {dd.email}
                        </h4>
                      </div>
                      <div className="dele_info">
                        <div className="dele_info_icon">
                          <img
                            src={userr}
                            className={
                              langmiamed == "ar"
                                ? "margin_left_20"
                                : "margin_right_20"
                            }
                          />
                          <span style={{ fontFamily: CheckFont(dd.gender) }}>
                            {dd.gender}
                          </span>
                        </div>
                        <div className="dele_info_icon">
                          <img
                            src={city}
                            className={
                              langmiamed == "ar"
                                ? "margin_left_20"
                                : "margin_right_20"
                            }
                          />
                          <span style={{ fontFamily: CheckFont(dd.city_name) }}>
                            {dd.city_name}
                          </span>
                        </div>
                        <div className="dele_info_icon">
                          <img
                            src={loc_icon}
                            className={
                              langmiamed == "ar"
                                ? "margin_left_20"
                                : "margin_right_20"
                            }
                          />
                          <span style={{ fontFamily: CheckFont(dd.address) }}>
                            {dd.address}
                          </span>
                        </div>
                        <div className="dele_info_icon">
                          <img
                            src={phone}
                            className={
                              langmiamed == "ar"
                                ? "margin_left_20"
                                : "margin_right_20"
                            }
                          />
                          <span style={{ fontFamily: CheckFont(dd.phone) }}>
                            {dd.phone}
                          </span>
                        </div>
                        {dd.users.map((uu) => {
                          return (
                            <div className="dele_info_icon">
                              <img
                                src={people}
                                className={
                                  langmiamed == "ar"
                                    ? "margin_left_20"
                                    : "margin_right_20"
                                }
                              />
                              <span
                                style={{ fontFamily: CheckFont(uu.first_name) }}
                              >
                                {uu.first_name} {uu.last_name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="div_btnd">
                        <button
                          className="del_btn_dele"
                          onClick={() => warning(dd)}
                        >
                          {translate("delete")}
                        </button>
                        <button
                          className="edit_btn_dele"
                          onClick={(event) => handleEditClick(event, dd)}
                        >
                          {translate("edit")}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="div_loadd">
                <InnerLoader />
              </div>
            )}
            {loadingmedicalRep == false && medicalRep.length == 0 && <NoData />}
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
          </div>
        </div>
      </div>
      {stateD.deletee == true ? (
        <Warning
          loadDel={stateD.loadDel}
          arr={stateD.arrW}
          open={stateD.deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {state.openEdit && (
        <EditMedicalRep
          t={translate}
          langmiamed={langmiamed}
          arr={state.arr1}
          open={state.openEdit}
          setOpen={setopenEdit}
        />
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default Delegates;
