import React, { useEffect, useState } from "react";
import useClickOutside from "../../useClickOutside";
import Add from "../General/Add";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import { postTargetGroup } from "../../Redux/Actions/ActionTargetGroup";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { VariableTargetGroup } from "./VariableTargetGroup";

Geocode.setApiKey("AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0");
const AnyReactComponent = () => (
  <div>
    <i className="fa fa-map-marker map_marker"></i>
  </div>
);
const FullScreen = () => (
  <div>
    <i className="fa fa-expand fullscreen"></i>
  </div>
);

function AddTargetGroup({
  currentPage,
  itemsPerPage,
  langmiamed,
  open,
  setOpen,
  t,
}) {
  let dispatch = useDispatch();
  const { state, handleChangeTargetGroup, setLoad } = VariableTargetGroup();
  const {
    cities,
    loadingCities,
    specializations,
    loadingspecializations,
    target_types,
    loadingtarget_type,
    classifications,
    loadingclassifications,
  } = useSelectorCustom();
  const {
    LoadCitiesCustom,
    LoadSpecializationsCustom,
    LoadTargetTypeCustom,
    LoadClassificationsCustom,
  } = FunctionsLoading();
  const [location, setLocation] = useState({
    map_lat: 33.510414,
    map_lng: 36.278336,
  });
  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  const handleMapClick = (event) => {
    const { lat, lng } = event;
    setLocation({ map_lat: lat, map_lng: lng });

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const handleApiLoaded = (map, maps) => {
    map.setOptions({ scrollwheel: false });
  };
  const test = (event) => {
    event.preventDefault();
    dispatch(
      postTargetGroup(
        currentPage,
        itemsPerPage,
        state.name,
        state.description,
        state.address,
        location.map_lat,
        location.map_lng,
        state.fixed_phone,
        state.phone_number,
        state.opening_time,
        state.closing_time,
        state.city_id,
        state.specialization_id,
        state.classification_id,
        state.target_type_id,
        state.medical_rep_name,
        setLoad,
        setOpen
      )
    );
  };
  return (
    <div
      className={`add_target_group ${
        langmiamed == "ar" ? "add_target_group_ar" : "add_target_group_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <Add
        langmiamed={langmiamed}
        icon="las la-plus-circle"
        title={`${t("addTarget")}`}
      />
      <form className="general_form">
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("name")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) => handleChangeTargetGroup(e.target.value, "name")}
        />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("description")}</span>
          <span className="require">*</span>
        </div>
        <textarea
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "description")
          }
        />
        <div className="form_header">
          <i
            className={`las la-map-marker-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("address")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) => handleChangeTargetGroup(e.target.value, "address")}
        />
        <div className="form_header">
          <i
            className={`las la-fax ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("fixed_num")}</span>
        </div>
        <input
          type="number"
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "fixed_phone")
          }
        />
        <div className="form_header">
          <i
            className={`las la-phone ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("phone")}</span>
        </div>
        <input
          type="number"
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "phone_number")
          }
        />
        <div className="form_header">
          <i
            className={`las la-map-marker-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("geotagging")}</span>
          <span className="require">*</span>
        </div>
        <br></br>
        <div className="div_map">
          <FullScreen />
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCPsxZeXKcSYK1XXw0O0RbrZiI_Ekou5DY",
            }}
            defaultCenter={{ lat: 33.510414, lng: 36.278336 }}
            center={{ lat: location.map_lat, lng: location.map_lng }}
            defaultZoom={12}
            onClick={handleMapClick}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            fullscreenControl={false}
          >
            <AnyReactComponent
              lat={location.map_lat}
              lng={location.map_lng}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
        <div className="form_header">
          <i
            className={`las la-city ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("cities")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingCities}
          title={t("cities")}
          array={cities}
          setId={(value) => handleChangeTargetGroup(value, "city_id")}
          funb={LoadCitiesCustom}
        />
        <div className="form_header">
          <i
            className={`las la-hand-pointer ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("specializations")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingspecializations}
          title={t("specializations")}
          array={specializations}
          setId={(value) => handleChangeTargetGroup(value, "specialization_id")}
          funb={LoadSpecializationsCustom}
        />
        <div className="form_header">
          <i
            className={`las la-calendar ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("opening_time")} </span>
          <span className="require">*</span>
        </div>
        <input
          type="time"
          className="margin_top_input"
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "opening_time")
          }
        />
        <div className="form_header">
          <i
            className={`las la-calendar ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("closing_time")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="time"
          className="margin_top_input"
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "closing_time")
          }
        />
        <div className="form_header">
          <i
            className={`las la-hand-pointer ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("classifications")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingclassifications}
          title={t("classifications")}
          array={classifications}
          setId={(value) => handleChangeTargetGroup(value, "classification_id")}
          funb={LoadClassificationsCustom}
        />
        <div className="form_header">
          <i
            className={`las la-hand-pointer ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("target_type")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingtarget_type}
          title={t("target_type")}
          array={target_types}
          setId={(value) => handleChangeTargetGroup(value, "target_type_id")}
          funb={LoadTargetTypeCustom}
          tt={true}
        />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("delegates")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "medical_rep_name")
          }
        />
        <button onClick={test}>
          {state.Load ? "Loading ..." : `${t("save")}`}
        </button>
      </form>
    </div>
  );
}

export default AddTargetGroup;
