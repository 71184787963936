import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import moment from "moment";
import { hasRout } from "../../Components/General/UseRout";

const addWorkPlan = () => {
  return {
    type: types.ADD_WORK_PLAN,
  };
};
const getWorkPlan = (workplans) => {
  return {
    type: types.GET_WORK_PLANS,
    payload: workplans,
  };
};
const delWorkPlan = () => {
  return {
    type: types.DELETE_WORK_PLAN,
  };
};
const editWorkPlan = () => {
  return {
    type: types.EDIT_WORK_PLAN,
  };
};
const getOneWorkPlan = (onePlan) => {
  return {
    type: types.GET_ONE_PLAN,
    payload: onePlan,
  };
};
const emptyGeneral = (empty, typee) => {
  return {
    type: typee,
    payload: empty,
  };
};
const refresh = () => {
  return {
    type: types.REFRESH_WORK_PLANE,
  };
};

export const loadWorkPlans = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(getWorkPlan(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const postWorkPlan = (
  note,
  start_date,
  end_date,
  is_in_service,
  days_duration,
  medical_rep_id,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/work-plans`,
        {
          note,
          start_date,
          end_date,
          is_in_service,
          days_duration,
          medical_rep_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(addWorkPlan());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadWorkPlans());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
export const deleteWorkPlan = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/work-plans/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delWorkPlan());
          dispatch(loadWorkPlans());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setdeletee(false);
        setloadDel(false);
        notifyError(err.response.data.message);
      });
  };
};

export const putWorkPlan = (
  id,
  note,
  start_date,
  end_date,
  is_in_service,
  days_duration,
  medical_rep_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/work-plans/${id}`,
        {
          note,
          start_date,
          end_date,
          is_in_service,
          days_duration,
          medical_rep_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(editWorkPlan());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadWorkPlans());
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
export const loadOneWorkPlans = (id, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plans/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(getOneWorkPlan(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};
export const emptyOneWorkPlan = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_ONE_PLAN));
  };
};

export const loadFilterWorkPlans = (
  medical_rep_id,
  start_date1,
  end_date1,
  setLoad
) => {
  return function (dispatch) {
    if (start_date1 == "" || end_date1 == "") {
      {
        setLoad(true);
        let token = localStorage.getItem("tokenMiamed");
        axios
          .get(
            `${base_url}api/work-plans?filter[medical_rep_id]=${medical_rep_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            dispatch(getWorkPlan(res.data.data));
            setLoad(false);
          })
          .catch((err) => {
            console.log(err);
            setLoad(false);
          });
      }
    } else {
      let start_date = moment(start_date1).format("YYYY-MM-DD");
      let end_date = moment(end_date1).format("YYYY-MM-DD");
      console.log(start_date);
      console.log(end_date);
      setLoad(true);
      let token = localStorage.getItem("tokenMiamed");
      axios
        .get(
          `${base_url}api/work-plans?filter[medical_rep_id]=${medical_rep_id}&filter[start_date]=${start_date}&filter[end_date]=${end_date}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          dispatch(getWorkPlan(res.data.data));
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    }
  };
};

export const refreshWorkPlans = (
  id,
  start_date,
  end_date,
  days_duration,
  navigate,
  setopenInternet,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    console.log("nour1");
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/work-plans/refresh/${id}`,
        {
          start_date,
          end_date,
          days_duration,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(refresh());
        dispatch(loadWorkPlans(navigate, setopenInternet));
        notifysuccess(res.data.message);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
