import { useState } from "react"

export const VariableDelegates = () => {
    const [state, setState] = useState({
        city_id: "",
        search_value: "",
        Load: false,
        first_name: "",
        last_name: "",
        email:"",
        password: "",
        address: "",
        phone: "",
        gender: "",
        position: "medical_rep",
        status: "1",
        city_id: "",
        city_name: "",
        user_id: "",
        user_name: "",
        work_flow_name: "",
        work_flow_id: "",
        Load1: false,
        arr1: [],
        open: false,
        openEdit: false
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setLoad1 = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load1: value,
        }));
    };
    const setStatuss = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            status: value,
        }));
    };
    const handleChangeDelegates = (value, variableName) => {
        //console.log(value)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const handleEditClick = (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            first_name: arr.first_name,
            last_name: arr.last_name,
            email: arr.email,
            gender: arr.gender,
            city: arr.city_name,
            address: arr.address,
            phone: arr.phone,
            position: arr.position,
            city_id: arr.city_id,
            status: arr.status,
            responsible_name: '',
            responsible_role: '',
            //work_flow_id: ''
        };
    
        if (arr.users.length !== 0) {
            formValues.responsible_name = `${arr.users[0].first_name} ${arr.users[0].last_name}`;
            formValues.responsible_role = arr.users[0].role;
            formValues.user_id = arr.users[0].id;
            formValues.work_flow_id = arr.users[0].pivot.work_flow_id;
        }
        setState((prevState) => ({
            ...prevState,
            arr1: formValues,
            openEdit: true
        }));
    }
    const setOpen = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            open: value,
        }));
    };
    const setopenEdit = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            openEdit: value,
        }));
    };
    const setuser_id = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            user_id: value,
        }));
    };
    const setuser_name = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            user_name: value,
        }));
    };
    return {
        ...state , 
        state , 
        setState,
        handleChangeDelegates,
        setLoad,
        setLoad1,
        setStatuss,
        handleEditClick,
        setOpen,
        setopenEdit,
        setuser_id,
        setuser_name
    }
}