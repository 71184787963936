import React, { useEffect, useState } from "react";
import Page_Header from "../General/Page_Header";
import "./TargetGroup.css";
import Pharmacies from "../../images/icons/pharIcon.png";
import hospital from "../../images/icons/hospitalIcon.png";
import doctor from "../../images/icons/doctorIcon.png";
import Health_center from "../../images/icons/pharIcon.png";
import AddTargetGroup from "./AddTargetGroup";
import FilterTargetGroup from "./FilterComponentTarget/FilterTargetGroup";
import ActionTop from "../General/ActionTop";
import { array_action } from "../../dummyData";
import {
  deleteTargetGroup,
  emptytargetGroup,
  loadTargetGroup,
} from "../../Redux/Actions/ActionTargetGroup";
import InnerLoader from "../General/InnerLoader";
import Warning from "../General/Warning_Delete/Warning";
import External_In_Map from "../External_Visites/External_In_Map";
import EditTarget from "./EditTarget";
import NewPagenation from "../General/Pagination/NewPagenation";
import loc_icon from "../../images/icons/location_icon.svg";
import fixed_phone from "../../images/icons/fixed_phone.png";
import phone from "../../images/icons/phoneIcon.png";
import calendar from "../../images/icons/date_icon.svg";
import loc_icon2 from "../../images/icons/loaction2.png";
import PopupExcel from "./FilterComponentTarget/PopupExcel";
import NoData from "../General/NoData/NoData";
import NoInternet from "../General/NotPermission/NoInternet";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { DeleteComponent } from "../General/GeneralComponent/DeleteComponent";
import { VariableTargetGroup } from "./VariableTargetGroup";
import { CheckFont } from "../General/GeneralComponent/CheckLang";
import moment from "moment";

function TargetGroup() {
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const { target_group, loadingTargetGroup } = useSelectorCustom();
  const { translate } = AbstructLang();
  const {
    state,
    handleEditTargetGroup,
    openAddNew,
    openMapp,
    setOpen,
    setisDelete,
    setisEdit,
    setOpenMap,
    setopenExcel,
    setisFilter,
    setopenEdit,
  } = VariableTargetGroup();
  const { stateD, warning, setdeletee, setloadDel } = DeleteComponent();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state.isFilter != "filter") {
      dispatch(
        loadTargetGroup(currentPage, itemsPerPage, setopenInternet, navigate)
      );
    }
    return () => {
      dispatch(emptytargetGroup());
    };
  }, [currentPage, itemsPerPage]);
  const handleDelete = (id) => {
    dispatch(
      deleteTargetGroup(
        id,
        setdeletee,
        currentPage,
        itemsPerPage,
        setisDelete,
        state.isFilter,
        setloadDel
      )
    );
  };
  const changePage = (newPage) => {
    {
      console.log(currentPage);
    }
    if (newPage >= 1 && target_group.length >= 0) {
      setCurrentPage(newPage);
    }
  };
  const exportToExcel = () => {
    setopenExcel(true);
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("target_group")}
            path={`${translate("home")} / ${translate("target_group")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
            FunctionCustum={openAddNew}
            is_view={true}
          />
          {state.open && (
            <AddTargetGroup
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              t={translate}
              langmiamed={langmiamed}
              open={state.open}
              setOpen={setOpen}
            />
          )}
          <FilterTargetGroup
            isDelete={state.isDelete}
            setisEdit={setisEdit}
            isEdit={state.isEdit}
            isFilter={state.isFilter}
            setisFilter={setisFilter}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            langmiamed={langmiamed}
            t={translate}
            setopenInternet={setopenInternet}
          />
          <div className="targetgroup" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingTargetGroup == false ? (
              target_group.map((item) => {
                return (
                  <div className="oneGroup" key={item.id}>
                    <div className="action_target">
                      <i
                        className="las la-times"
                        onClick={() => warning(item)}
                      ></i>
                      <i
                        className="las la-pen"
                        onClick={(event) => {
                          handleEditTargetGroup(event, item);
                        }}
                      ></i>
                    </div>
                    <span
                      className="target_type"
                      style={{
                        fontFamily: CheckFont(translate(item.target_type_name)),
                      }}
                    >
                      {translate(item.target_type_name)}
                    </span>
                    {item.target_type_name == "صيدليات" ? (
                      <img src={Pharmacies} />
                    ) : item.target_type_name == "مشافي" ? (
                      <img src={hospital} />
                    ) : item.target_type_name == "أطباء" ? (
                      <img className="doctorr" src={doctor} />
                    ) : (
                      <img src={Health_center} />
                    )}
                    <>
                      <h5
                        className="group_header"
                        style={{ fontFamily: CheckFont(item.name) }}
                        dir={langmiamed == "ar" ? "rtl" : "ltr"}
                      >
                        {" "}
                        {item.name}
                        {item.classification_id != null && (
                          <h5
                            className={`group_cat ${
                              langmiamed == "ar"
                                ? "margin_right_20"
                                : "margin_left_20"
                            }`}
                            style={{
                              fontFamily: CheckFont(item.classification_name),
                            }}
                          >
                            {item.classification_name}
                          </h5>
                        )}
                      </h5>
                      {item.specialization_id != null ? (
                        <h6
                          className="group_header"
                          style={{
                            fontFamily: CheckFont(item.specialization_name),
                          }}
                        >
                          {item.specialization_name}
                        </h6>
                      ) : (
                        <h6 className="group_header defHeight"></h6>
                      )}
                      <p>{item.description}</p>
                      <div
                        className="group_address linkMap"
                        onClick={() => openMapp(item.lat, item.lng)}
                      >
                        <img
                          src={loc_icon}
                          className={
                            langmiamed == "ar"
                              ? "margin_left_10"
                              : "margin_right_10"
                          }
                        />
                        {item.address != null ? (
                          <span
                            className="ex_loc"
                            style={{ fontFamily: CheckFont(item.address) }}
                          >
                            {item.address}
                          </span>
                        ) : (
                          <span className="ex_loc">
                            {translate("clicktosee")}
                          </span>
                        )}
                      </div>

                      <div
                        className="group_address"
                        dir={langmiamed == "ar" ? "rtl" : "ltr"}
                      >
                        <img
                          src={fixed_phone}
                          className={
                            langmiamed == "ar"
                              ? "margin_left_10"
                              : "margin_right_10"
                          }
                        />
                        <span
                          style={{ fontFamily: CheckFont(item.fixed_phone) }}
                        >
                          {translate("date")} :{" "}
                          {moment(item["created_at"]).format("YYYY-MM-DD")}
                        </span>
                      </div>
                      <div
                        className="group_address"
                        dir={langmiamed == "ar" ? "rtl" : "ltr"}
                      >
                        <img
                          src={phone}
                          className={
                            langmiamed == "ar"
                              ? "margin_left_10"
                              : "margin_right_10"
                          }
                        />
                        <span
                          style={{ fontFamily: CheckFont(item.phone_number) }}
                        >
                          {translate("phone")} : {item.phone_number}
                        </span>
                      </div>
                      <div
                        className="group_address"
                        dir={langmiamed == "ar" ? "rtl" : "ltr"}
                      >
                        <img
                          src={calendar}
                          className={
                            langmiamed == "ar"
                              ? "margin_left_10"
                              : "margin_right_10"
                          }
                        />
                        <span
                          style={{ fontFamily: CheckFont(item.opening_time) }}
                        >
                          {translate("time")} : {item.opening_time} ...{" "}
                          {item.closing_time}
                        </span>
                      </div>
                      {item.city_name == null ? (
                        <div className="group_address defHeight1"></div>
                      ) : (
                        <div
                          className="group_address"
                          dir={langmiamed == "ar" ? "rtl" : "ltr"}
                        >
                          <img
                            src={loc_icon2}
                            className={`loc22 ${
                              langmiamed == "ar"
                                ? "margin_left_10"
                                : "margin_right_10"
                            }`}
                          />
                          <span
                            style={{ fontFamily: CheckFont(item.city_name) }}
                          >
                            {item.city_name}
                          </span>
                        </div>
                      )}
                    </>
                  </div>
                );
              })
            ) : (
              <div className="div_loadd">
                <InnerLoader />
              </div>
            )}
            {loadingTargetGroup == false && target_group.length == 0 && (
              <NoData />
            )}
            <NewPagenation
              target_group={target_group}
              currentPage={currentPage}
              changePage={changePage}
            />
          </div>
        </div>
      </div>
      {stateD.deletee == true ? (
        <Warning
          loadDel={stateD.loadDel}
          arr={stateD.arrW}
          open={stateD.deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setOpenMap}
          lat={state.lat}
          lng={state.lng}
        />
      )}
      {state.openEdit && (
        <EditTarget
          isFilter={state.isFilter}
          isEdit={state.isEdit}
          setisEdit={setisEdit}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          arr={state.arr1}
          open={state.openEdit}
          setOpen={setopenEdit}
        />
      )}
      {state.openExcel && (
        <PopupExcel
          langmiamed={langmiamed}
          t={translate}
          open={state.openExcel}
          setOpen={setopenExcel}
        />
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default TargetGroup;
