import React, { useEffect, useState } from 'react'
import Page_Header from '../General/Page_Header';
import { array_action } from '../../dummyData';
import ActionTop from '../General/ActionTop';
import './External_Visites.css'
import External_In_Map from './External_In_Map';
import FilterExternal from './FilterExternal';
import { loadVisites } from '../../Redux/Actions/ActionExternalVisites';
import InnerLoader from '../General/InnerLoader';
import SinglePagenation from '../General/Pagination/SinglePagenation';
import { exportFile } from '../General/Notification';
import peoplee from '../../images/icons/people_blue.png'
import loc_icon2 from '../../images/icons/loaction2.png'
import loc_icon from '../../images/icons/location_icon.svg'
import person from '../../images/icons/user_blue.png'
import calendar from '../../images/icons/date_icon.svg'
import NoData from '../General/NoData/NoData';
import moment from 'moment';
import NoInternet from '../General/NotPermission/NoInternet';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { PagenationCustom } from '../General/GeneralComponent/PagenationCustom';
import { VariableExternalVisit } from './VariableExternalVisit';
import { CheckFont } from '../General/GeneralComponent/CheckLang';

function External_Visites() {
    const [openInternet, setopenInternet] = useState(false)
    let {dispatch , navigate , langmiamed} = AbstructVar()
    const {translate} = AbstructLang()
    const {external_visites,loadingexternal_visites} = useSelectorCustom()
    const reversedexternal_visites = [...external_visites].reverse()
    const {state , openMap,setOpen} = VariableExternalVisit()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadVisites(navigate,setopenInternet))
    }, []);
    const {displayArr , pageCount , changePage} = PagenationCustom(reversedexternal_visites , 12)
    const exportToExcel = () => {
        exportFile(reversedexternal_visites, 'external_visites_data')
    };
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={translate('external_visites')} path={`${translate('home')} / ${translate('external_visites')} `} />
                </main>
                <div className="page-content" >
                    <ActionTop exportToExcel={exportToExcel} langmiamed={langmiamed} array_action={array_action} is_view={false} />
                    <FilterExternal navigate={navigate} setopenInternet={setopenInternet} langmiamed={langmiamed} t={translate} />
                    <div className='allExternal' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                        {
                            loadingexternal_visites == false ? (
                                displayArr.map((ee) => {
                                    return (
                                        <div className="plan"  >
                                            <div className="inner">
                                                <span className={`pricing ${langmiamed == 'ar' ? "pricing_right" : "pricing_left"} `}>
                                                    <span>
                                                        {translate('external')}
                                                    </span>
                                                </span>
                                                <p className={`title ${langmiamed == 'ar' ? "textRight" : ""} `}>{translate(ee.report_type_name)}</p>
                                                <p style={{fontFamily: CheckFont(ee.description)}} className={`info  ${langmiamed == 'ar' ? "textRight" : ""} `}>{ee.description}</p>
                                                <ul className="features">
                                                    <li>
                                                        <span className="icon">
                                                            <img className='peoplee' src={peoplee} />
                                                        </span>
                                                        <span style={{fontFamily: CheckFont(ee.target_group_name)}}>{ee.target_group_name}</span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img className='loc222' src={loc_icon2} />
                                                        </span>
                                                        <span style={{fontFamily: CheckFont(ee.city_name)}}>{ee.city_name} / <span style={{fontFamily: CheckFont(ee.address)}}>{ee.address}</span></span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img src={person} />
                                                        </span>
                                                        <span style={{fontFamily: CheckFont(ee.medical_rep_name)}}>{ee.medical_rep_name}</span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img src={calendar} />
                                                        </span>
                                                        <span>{moment(ee.created_at).format("YYYY-MM-DD")} / {moment(ee.created_at).format("HH:mm A")}</span>
                                                    </li>
                                                    <li onClick={() => openMap(ee.lat, ee.lng)} className='linkMap' >
                                                        <span className="icon">
                                                            <img src={loc_icon} />
                                                        </span>
                                                        <span className={`ex_loc ${langmiamed === 'ar' ? "textRight" : ""}`} >{translate('seeLoc')}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })) : <div className='div_loadd' ><InnerLoader /></div>
                        }
                        {
                            loadingexternal_visites == false && external_visites.length == 0 &&(
                                <NoData />
                            )
                        }
                        <SinglePagenation pageCount={pageCount} changePage={changePage} />
                    </div>
                </div>
            </div>
            {
                state.open && (
                    <External_In_Map open={state.open} setOpen={setOpen} lat={state.lat} lng={state.lng} />
                )
            }
            {
                openInternet && (
                    <NoInternet open={openInternet} setOpen={setopenInternet} />
                )
            }
        </>
    )
}

export default External_Visites