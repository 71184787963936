import React, { useEffect,useState } from 'react'
import floww from '../../images/workflow.png'
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import './WorkFlow.css'
import useClickOutside from '../../useClickOutside';
import { putWorkFlow } from '../../Redux/Actions/ActionWorkFlow';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';
import { VariableWorkFlow } from './VariableWorkFlow';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';

function EditWorkFlow({ langmiamed, arr, setwork_flow_id }) {
    let dispatch = useDispatch();
    const { cities, loadingCities } = useSelectorCustom()
    const { LoadCitiesCustom } = FunctionsLoading()
    const { state, setState, handleChangeVariableWorkFlow, setLoad } = VariableWorkFlow()
    const [status , setStatus] = useState(arr.status === true ? 1 : 0)
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            name: arr.name,
            status: arr.status === true ? 1 : 0,
            description: arr.description,
            city_name: arr.city_name,
            city_id: arr.city_id
        }));
    }, [])
    let domNode = useClickOutside(() => {
        setwork_flow_id(null);
    });
    const activee = () => {
        setStatus(prevValue => (prevValue === 1 ? 0 : 1));
    };
    const submit = event => {
        event.preventDefault();
        dispatch(putWorkFlow(arr.id, state.name, state.description, status, state.city_id, setwork_flow_id, setLoad))
    }
    return (
        <div className='oneFlow' ref={domNode} >
            <Switch className='swith_flow' onColor='#4285C5' checkedIcon={false} uncheckedIcon={false} height={20} width={40} handleDiameter={15} checked={status} onChange={activee} />
            <div className='header_flow' style={{ marginTop: "20px" }} >
                <img className={langmiamed == 'ar' ? 'margin_left_20' : "margin_right_20"} src={floww} />
                <input className='input_edit_flow' defaultValue={state.name} onChange={(e) => handleChangeVariableWorkFlow(e.target.value, 'name')} />
            </div>
            <SelectWithSearch
                load={loadingCities}
                array={cities}
                setId={(value) => handleChangeVariableWorkFlow(value, 'city_id')}
                funb={LoadCitiesCustom}
                name={state.city_name}
                previousID={state.city_id}
            />
            <textarea className='textarea_flow' defaultValue={state.description} onChange={(e) => handleChangeVariableWorkFlow(e.target.value, 'description')} />
            <div className='flow_icon' >
                <i className={state.Load ? "las la-spinner editFlow " : "las la-save editFlow"} onClick={submit} ></i>
            </div>
        </div>
    )
}

export default EditWorkFlow