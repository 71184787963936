import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { loadFilterProduct, loadProduct } from '../../Redux/Actions/ActionProduct';
import nofilterr from '../../images/icons/nofilter.png'
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';
import { VariableProducts } from './VariableProducts';

function FilterProduct({ setopenInternet,navigate,t, langmiamed }) {
    let dispatch = useDispatch();
    const {measurement,loadingmeasurement,productCat,loadingproductCat} = useSelectorCustom()
    const {LoadMeasurementsCustom,LoadProductCatCustom} = FunctionsLoading()
    const {state ,setState, handleChangeProducts,setLoad} = VariableProducts()
    const [isEmpty , setisEmpty] = useState(false)
    const [product_type,setproduct_type] = useState('')
    const filter = event => {
        event.preventDefault()
        dispatch(loadFilterProduct(state.measurement_unit_id,product_type,state.search_value,state.product_category_id,setLoad))
    }
    const Unfilter = event => {
        event.preventDefault()
        setState((prevState) => ({
            ...prevState,
            search_value: '',
            product_type: '',
        }));
        setproduct_type('')
        setisEmpty(true)
        dispatch(loadProduct(navigate,setopenInternet))
    }
    return (
        <div className={`targetfilter ${langmiamed == 'ar' ? "padding_right_10" : ""}`} dir={langmiamed == "ar" ? "rtl" : "ltr"} >
            <div className='oneFilter' >
                <input value={state.search_value} placeholder={` ${t('search')} ...`} onChange={(e) => handleChangeProducts(e.target.value, 'search_value')} />
            </div>
            <div className='oneFilter' >
                <SelectWithSearch
                    load={loadingmeasurement}
                    typeCss={true}
                    title={t('unit')}
                    array={measurement}
                    setId={(value) => handleChangeProducts(value, 'measurement_unit_id')}
                    funb={LoadMeasurementsCustom}
                    isEmpty={isEmpty}
                />
            </div>
            <div className='oneFilter' >
                <SelectWithSearch
                    load={loadingproductCat}
                    typeCss={true}
                    title={t('product_cat')}
                    array={productCat}
                    setId={(value) => handleChangeProducts(value, 'product_category_id')}
                    funb={LoadProductCatCustom}
                    isEmpty={isEmpty}
                />
            </div>
            <div className='oneFilter' >
                <select defaultValue={product_type} onChange={(e) => setproduct_type(e.target.value)}>
                    <option disabled="disabled" selected="selected">{t('product_type')}</option>
                    <option value='marketing_tools' >Marketing Tools</option>
                    <option value='medicine_samples' >Free Samples</option>
                </select>
            </div>
            <button className='targetfilter_button' onClick={filter} >
                {
                    state.Load ? "Loading ..." : `${t('filter')}`
                }
            </button>
            <img onClick={Unfilter} title={t('unfilter')} className='targetfilter_button_img' src={nofilterr} />
        </div>
    )
}

export default FilterProduct