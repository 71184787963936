import { useState } from "react"

export const VariableWorkFlow = () => {
    const [state, setState] = useState({
        name: "",
        description: "",
        status: "1",
        Load: false,
        city_id: "",
        city_name: "",
        work_flow_id: "",
        work_flow_name: "",
        open: false,
        arr1: []
    })
    const handleChangeVariableWorkFlow = (value, variableName) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const setstatus = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            status: value,
        }));
    };
    const setLoad = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const handleEditWorkFlow = (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            name: arr.name,
            description: arr.description,
            city_id: arr.city_id,
            city_name: arr.city_name,
            status: arr.status
        };
        setState((prevState) => ({
            ...prevState,
            work_flow_id: arr.id,
            arr1: formValues
        }));
    }
    const setwork_flow_id = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            work_flow_id: value,
        }));
    };
    const openAddNew = () => {
        setState((prevState) => ({
            ...prevState,
            open: true,
        }));
    }
    const setOpen = (value) => {
        setState((prevState) => ({
            ...prevState,
            open: value,
        }));
    }
    return{
        ...state,
        state,
        setState,
        handleChangeVariableWorkFlow,
        setstatus,
        setLoad,
        handleEditWorkFlow,
        setwork_flow_id,
        openAddNew,
        setOpen
    }
}