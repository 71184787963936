import React, { useState, useEffect } from "react";
import Page_Header from "../General/Page_Header";
import { array_action } from "../../dummyData";
import ActionTop from "../General/ActionTop";
import "./Vacation.css";
import ReactLoading from "react-loading";
import FilterVacation from "./FilterVacation";
import {
  loadVacation,
  statusVacation,
} from "../../Redux/Actions/actionVacation";
import InnerLoader from "../General/InnerLoader";
import moment from "moment";
import SinglePagenation from "../General/Pagination/SinglePagenation";
import { exportFile } from "../General/Notification";
import NoData from "../General/NoData/NoData";
import NoInternet from "../General/NotPermission/NoInternet";
import dailyImg from "../../images/icons/calDaily.png";
import hourlyImg from "../../images/icons/hourly.png";
import userr from "../../images/new side icon/profile-user.png";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { PagenationCustom } from "../General/GeneralComponent/PagenationCustom";
import { VariableVacations } from "./VariableVacations";
import { CheckFont } from "../General/GeneralComponent/CheckLang";
import EditVacation from "./EditVacation";
import { useTranslation } from "react-i18next";

function Vacation() {
    const {t} = useTranslation()
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const { translate } = AbstructLang();
  const { Vacation, loadingVacation } = useSelectorCustom();
  const reversedVacation = [...Vacation].reverse();
  const { displayArr, pageCount, changePage } = PagenationCustom(
    reversedVacation,
    12
  );
  const { state, changeStatusVacation, handleEditVacation, setopenEdit } =
    VariableVacations();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadVacation(navigate, setopenInternet));
  }, []);
  const exportToExcel = () => {
    exportFile(reversedVacation, "vacation_data");
  };
  console.log("Vacation", Vacation?.length);
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("vacation")}
            path={`${translate("home")} / ${translate("vacation")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
          />
          <FilterVacation
            navigate={navigate}
            setopenInternet={setopenInternet}
            langmiamed={langmiamed}
            t={translate}
          />
            <h5 style={{display:'flex' ,justifyContent:'center',color:'gray'}}> {t("Number of vacations") + ": " + Vacation?.length}</h5>

          <div className="allvacation" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingVacation == false ? (
              displayArr.map((vv) => {
                return (
                  <div className="allonevacation" key={vv.id}>
                    <div className="onevacation">
                      <div className="action_target">
                        <i
                          className="las la-pen"
                          onClick={(event) => {
                            handleEditVacation(event, vv);
                          }}
                        ></i>
                      </div>
                      {vv.type === "daily" ? (
                        <img
                          alt={translate("day_off")}
                          title={translate("day_off")}
                          src={dailyImg}
                        />
                      ) : (
                        <img
                          alt={translate("half_day")}
                          title={translate("half_day")}
                          src={hourlyImg}
                        />
                      )}

                      {vv.type == "daily" ? (
                        <div className="under_img">
                          <div className="div_date_vac">
                            <i
                              className={`las la-calendar ${
                                langmiamed == "ar"
                                  ? "margin_left_10"
                                  : "margin_right_10"
                              }`}
                            ></i>
                            <span className="date_vac">
                              {translate("startDate")}:{" "}
                              {moment(vv.start).format("YYYY-MM-DD")}
                            </span>
                          </div>
                          <div className="div_date_vac">
                            <i
                              className={`las la-calendar ${
                                langmiamed == "ar"
                                  ? "margin_left_10"
                                  : "margin_right_10"
                              }`}
                            ></i>
                            <span className="date_vac">
                              {translate("endDate")}:{" "}
                              {moment(vv.end).format("YYYY-MM-DD")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="under_img">
                          <div className="div_date_vac">
                            <i
                              className={`las la-calendar ${
                                langmiamed == "ar"
                                  ? "margin_left_10"
                                  : "margin_right_10"
                              }`}
                            ></i>
                            <span className="date_vac">
                              {translate("day")}:{" "}
                              {moment(vv.start).format("YYYY-MM-DD")}
                            </span>
                          </div>
                          <div className="div_date_vac">
                            <i
                              className={`las la-calendar ${
                                langmiamed == "ar"
                                  ? "margin_left_10"
                                  : "margin_right_10"
                              }`}
                            ></i>
                            <span className="date_vac">
                              {translate("hour")}:{" "}
                              {moment(vv.start).format("HH:mm A")} /{" "}
                              {moment(vv.end).format("HH:mm A")}
                            </span>
                          </div>
                        </div>
                      )}
                      <p
                        style={{ fontFamily: CheckFont(vv.reason) }}
                        className={`div_reason ${
                          langmiamed === "ar" ? "reason_end" : "reason_start"
                        }`}
                        dir="ltr"
                      >
                        {vv.reason}{" "}
                      </p>
                      <div className="delegate_name">
                        <img
                          src={userr}
                          className={
                            langmiamed == "ar"
                              ? "margin_left_10"
                              : "margin_right_10"
                          }
                        />
                        <span
                          style={{ fontFamily: CheckFont(vv.medical_rep_name) }}
                        >
                          {vv.medical_rep_name}
                        </span>
                      </div>
                    </div>
                    <div className="btn_vac">
                      {state.Load ? (
                        state.vacationId === vv.id ? (
                          <ReactLoading
                            type="spinningBubbles"
                            color="#f256568d "
                            height={"14%"}
                            width={"14%"}
                          />
                        ) : (
                          <select
                            style={{ fontFamily: CheckFont(vv.status) }}
                            value={vv.status}
                            onChange={(e) =>
                              changeStatusVacation(vv.id, e.target.value)
                            }
                          >
                            <option
                              style={{ fontFamily: CheckFont("Pending") }}
                            >
                              Pending
                            </option>
                            <option
                              style={{ fontFamily: CheckFont("Confirmed") }}
                            >
                              Confirmed
                            </option>
                            <option
                              style={{ fontFamily: CheckFont("Canceled") }}
                            >
                              Canceled
                            </option>
                          </select>
                        )
                      ) : (
                        <select
                          style={{ fontFamily: CheckFont(vv.status) }}
                          value={vv.status}
                          onChange={(e) =>
                            changeStatusVacation(vv.id, e.target.value)
                          }
                        >
                          <option style={{ fontFamily: CheckFont("Pending") }}>
                            Pending
                          </option>
                          <option
                            style={{ fontFamily: CheckFont("Confirmed") }}
                          >
                            Confirmed
                          </option>
                          <option style={{ fontFamily: CheckFont("Canceled") }}>
                            Canceled
                          </option>
                        </select>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="div_loadd">
                <InnerLoader />
              </div>
            )}
            {loadingVacation == false && Vacation.length == 0 && <NoData />}
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
          </div>
        </div>
      </div>
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
      {state.openEdit && (
        <EditVacation
          arr={state.arr1}
          open={state.openEdit}
          setOpen={setopenEdit}
        />
      )}
    </>
  );
}

export default Vacation;
