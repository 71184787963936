import { useState } from "react";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

export const VariableReports = () => {
  const [state, setState] = useState({
    openMap: false,
    lat: false,
    lng: false,
    isTap: "target_group_visits",
  });
  const storeMap = (latt, lngg) => {
    setState((prevState) => ({
      ...prevState,
      lat: latt,
      lng: lngg,
      openMap: true,
    }));
  };
  const setIsTap = (value) => {
    setState((prevState) => ({
      ...prevState,
      isTap: value,
    }));
  };
  const setIsOpen = (value) => {
    setState((prevState) => ({
      ...prevState,
      openMap: value,
    }));
  };
  return {
    ...state,
    state,
    setState,
    storeMap,
    setIsTap,
    setIsOpen,
  };
};

export const ColumnArrayReports = (translate, storeMap) => {
  const Column = [
    {
      name: <span className="color_spann">{translate("target_group")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("address")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_address) }}>
          {row["target_group_address"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("delegates")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("target_type")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_type) }}>
          {row["target_group_type"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("location")}</span>,
      sortable: true,
      cell: (row) => (
        <span>
          {row.site_match ? (
            <i className="las la-check checkmatch"></i>
          ) : (
            <i className="las la-times falsematch"></i>
          )}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("reportType")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.report_type_name) }}>
          {row["report_type_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("houre")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_time) }}>
          {row["visit_time"]}
        </span>
      ),
    },
    {
      name: (
        <span width="100%" className="color_spann">
          {translate("description")}
        </span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_details) }}>
          {row["visit_details"]}
        </span>
      ),
      width: "250px",
    },
    {
      name: <span className="color_spann">{translate("map")}</span>,
      cell: (row) => (
        <i
          className="las la-map-marked icon_edit_table"
          onClick={() => storeMap(row.lat, row.lng)}
        ></i>
      ),
    },
  ];
  return {
    Column,
  };
};
export const ColumnArrayReportObjections = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("target_group_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("objection")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_details) }}>
          {row["visit_details"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportRequests = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("target_group_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("Request")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_details) }}>
          {row["visit_details"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayMonthlyOrders = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.visit_date) }}>
          {row["visit_date"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("target_group_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_group_name) }}>
          {row["target_group_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("productName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.product_name) }}>
          {row["product_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("quantity")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.quantityProds) }}>
          {row["quantityProds"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayMonthlyProductsSales = (translate, storeMap) => {
  const productsMonthleSalesColumn = [
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("product_name")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.name) }}>{row["name"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("target_product")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target) }}>
          {row["target"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("achived_product")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.achive) }}>
          {row["achive"]}
        </span>
      ),
    },
  ];
  return {
    productsMonthleSalesColumn,
  };
};
export const ColumnArrayMedicalRepTarget = (translate, storeMap) => {
  const medicalRepTargetColumn = [
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("total_target")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_medical_reps_quantity) }}>
          {row["target_medical_reps_quantity"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("total_achieved_sales")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_medical_reps_quantity2) }}>
          {row["target_medical_reps_quantity2"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("percentage")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_medical_reps_quantity3) }}>
          {row["target_medical_reps_quantity3"]}
        </span>
      ),
    },
  ];
  return {
    medicalRepTargetColumn,
  };
};
export const ColumnArrayReportTargetVisits = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="color_spann">{translate("target_type_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.target_type_name) }}>
          {row["target_type_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("specialization_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.specialization_name) }}>
          {row["specialization_name"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("classification_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.classification_name) }}>
          {row["classification_name"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("number_of_visit")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.count) }}>{row["count"]}</span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};

export const ColumnArrayReportAllVisits = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("city_name")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.country_name) }}>
          {row["country_name"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("region_name")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.city_name) }}>
          {row["city_name"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("medical_rep_name")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medical_rep_name) }}>
          {row["medical_rep_name"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("planed_visits")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.planed_visits) }}>
          {row["planed_visits"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("actual_visits")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.actual_visits) }}>
          {row["actual_visits"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">
          {translate("achieved_rate_of_visits")}
        </span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.achieved_rate_of_visits) }}>
          {row["achieved_rate_of_visits"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">{translate("unachieved_visits")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.unachieved_visits) }}>
          {row["unachieved_visits"]}
        </span>
      ),
    },

    {
      name: (
        <span className="color_spann">{translate("over_visited_target")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.over_visited_target) }}>
          {row["over_visited_target"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("new_target")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.new_target) }}>
          {row["new_target"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportFreeSamples = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.countryName) }}>
          {row["countryName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medicalName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medicalName) }}>
          {row["medicalName"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("freeSamplesName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.productName) }}>
          {row["productName"]}
        </span>
      ),
      width: "200px",
    },

    {
      name: (
        <span className="color_spann">
          {translate("free_samples_quantity")}
        </span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.freeSamplesQuantity) }}>
          {row["freeSamplesQuantity"]}
        </span>
      ),
    },
    {
      name: (
        <span className="color_spann">
          {translate("free_samples_quantity")}
        </span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.freeSamplesQuantity) }}>
          {row["freeSamplesQuantity"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("batchNumber")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.batchNumber) }}>
          {row["batchNumber"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportFreeSamplesTargets = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.countryName) }}>
          {row["countryName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.cityName) }}>
          {row["cityName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medicalName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medicalName) }}>
          {row["medicalName"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("targetName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.targetName) }}>
          {row["targetName"]}
        </span>
      ),
      width: "150px",
    },
    {
      name: <span className="color_spann">{translate("productName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.productName) }}>
          {row["productName"]}
        </span>
      ),
      width: "150px",
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
export const ColumnArrayReportMarketingTools = (translate, storeMap) => {
  const OrdersReqsColumn = [
    {
      name: <span className="color_spann">{translate("date")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.date) }}>{row["date"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("countryName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.countryName) }}>
          {row["countryName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("cityName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.cityName) }}>
          {row["cityName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("medicalName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.medicalName) }}>
          {row["medicalName"]}
        </span>
      ),
    },

    {
      name: <span className="color_spann">{translate("targetName")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.targetName) }}>
          {row["targetName"]}
        </span>
      ),
      width: "150px",
    },

    {
      name: (
        <span className="color_spann">{translate("marketingToolName")}</span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.toolName) }}>
          {row["toolName"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{translate("code")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.code) }}>{row["code"]}</span>
      ),
    },
    {
      name: (
        <span className="color_spann">
          {translate("marketingToolsQuantity")}
        </span>
      ),
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.toolsQuantity) }}>
          {row["toolsQuantity"]}
        </span>
      ),
    },
  ];
  return {
    OrdersReqsColumn,
  };
};
