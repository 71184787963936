import React, { useState, useEffect } from "react";
import useClickOutside from "../../useClickOutside";
import Add from "../General/Add";
import { useDispatch } from "react-redux";
import {
  emptySenior,
  loadSeniorSupervisor,
} from "../../Redux/Actions/ActionAuth";
import Switch from "react-switch";
import ReactLoading from "react-loading";
import { VariableDelegates } from "./VariableDelegates";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { postMedicalRep } from "../../Redux/Actions/ActionMedicalRep";

function AddDelegates({ t, langmiamed, setOpen }) {
  let dispatch = useDispatch();
  const { state, handleChangeDelegates, setLoad, setLoad1, setStatuss } =
    VariableDelegates();
  const { LoadCitiesCustom, LoadworkFlowCustom } = FunctionsLoading();
  const {
    cities,
    loadingCities,
    senior_supervisor,
    loadingsenior_supervisor,
    workflow,
    loadingWorkflow,
  } = useSelectorCustom();
  const [user_role, setuser_role] = useState("");
  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  useEffect(() => {
    return () => {
      dispatch(emptySenior());
    };
  }, []);
  const view = (ser) => {
    setuser_role(ser);
    if (ser == "senior_medical_rep") {
      dispatch(loadSeniorSupervisor("senior_medical_rep", setLoad1));
    }
    if (ser == "senior_supervisor") {
      dispatch(loadSeniorSupervisor("senior_supervisor", setLoad1));
    }
    if (ser == "supervisor") {
      dispatch(loadSeniorSupervisor("supervisor", setLoad1));
    }
  };

  const submit = (event) => {
    event.preventDefault();
    console.log("city_id" + state.city_id);
    console.log("user_id" + state.user_id);
    console.log("work_flow_id" + state.work_flow_id);
    dispatch(
      postMedicalRep(
        state.first_name,
        state.last_name,
        state.email,
        state.password,
        state.address,
        state.phone,
        state.gender,
        state.status,
        state.position,
        state.city_id,
        state.user_id,
        8,
        setLoad,
        setOpen
      )
    );
  };
  const activee = () => {
    const newValue = state.status === "0" ? "1" : "0";
    setStatuss(newValue);
  };
  return (
    <div
      className={`add_target_group ${
        langmiamed == "ar" ? "add_target_group_ar" : "add_target_group_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <Add
        langmiamed={langmiamed}
        icon="las la-plus-circle"
        title={`${t("addDelegate")}`}
      />
      <form className="general_form">
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("fistName")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) => handleChangeDelegates(e.target.value, "first_name")}
        />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("lastName")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) => handleChangeDelegates(e.target.value, "last_name")}
        />
        <div className="form_header">
          <i
            className={`las la-envelope-open-text ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("email")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) => handleChangeDelegates(e.target.value, "email")}
        />
        <div className="form_header">
          <i
            className={`las la-key ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("password")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="password"
          onChange={(e) => handleChangeDelegates(e.target.value, "password")}
        />
        <div className="form_header">
          <i
            className={`las la-map-marked-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("address")}</span>
          <span className="require">*</span>
        </div>
        <input
          onChange={(e) => handleChangeDelegates(e.target.value, "address")}
        />
        <div className="form_header">
          <i
            className={`las la-fingerprint ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("id")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="number"
          onChange={(e) => handleChangeDelegates(e.target.value, "phone")}
        />
        <div className="form_header">
          <i
            className={`las la-user ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("gender")}</span>
          <span className="require">*</span>
        </div>
        <select
          onChange={(e) => handleChangeDelegates(e.target.value, "gender")}
        >
          <option disabled="disabled" selected="selected">
            {t("gender")}
          </option>
          <option value="female">Female</option>
          <option value="male">Male</option>
        </select>
        <div className="form_header">
          <i
            className={`las la-city ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("cities")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingCities}
          title={t("cities")}
          array={cities}
          setId={(value) => handleChangeDelegates(value, "city_id")}
          funb={LoadCitiesCustom}
        />
        <div className="form_header">
          {state.Load1 ? (
            <ReactLoading
              type="spinningBubbles"
              color="#9699c2"
              height={"5%"}
              width={"5%"}
            />
          ) : (
            <i
              className={`las la-user ${
                langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
              }`}
            ></i>
          )}
          <span>{t("responsible")}</span>
          <span className="require">*</span>
        </div>
        <select onChange={(e) => view(e.target.value)}>
          <option disabled="disabled" selected="selected">
            {t("responsible")}
          </option>
          <option value="senior_supervisor">senior supervisor</option>
          <option value="supervisor">supervisor</option>
          <option value="senior_medical_rep">senior medical rep</option>
        </select>
        <div className="form_header">
          <i
            className={`las la-city ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("workFlow")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingWorkflow}
          title={t("workFlow")}
          array={workflow}
          setId={(value) => handleChangeDelegates(value, "work_flow_id")}
          funb={LoadworkFlowCustom}
        />
        {senior_supervisor.length != 0 ? (
          <>
            <div className="form_header">
              <i
                className={`las la-city ${
                  langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                }`}
              ></i>
              <span>{user_role}</span>
              <span className="require">*</span>
            </div>
            <SelectWithSearch
              load={loadingsenior_supervisor}
              title={user_role}
              array={senior_supervisor}
              setId={(value) => handleChangeDelegates(value, "user_id")}
            />
          </>
        ) : (
          <></>
        )}
        <div className="form_header">
          <i
            className={`las la-city ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("status")}</span>
          <span className="require">*</span>
        </div>
        <br></br>
        <Switch
          onColor="#4285C5"
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={40}
          handleDiameter={15}
          checked={state.status === "1"}
          onChange={activee}
        />
        <br></br>
        <button onClick={submit}>
          {state.Load ? "Loading ..." : `${t("save")}`}
        </button>
      </form>
    </div>
  );
}

export default AddDelegates;
