import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";
import moment from "moment";

const get = (arr, type) => {
  return {
    type: type,
    payload: arr,
  };
};
const getReports = (reports) => {
  return {
    type: types.GET_ALL_REPORTS,
    payload: reports,
  };
};
const getGeneralReports = (generalReport) => {
  return {
    type: types.GET_GENERAL_REPORTS,
    payload: generalReport,
  };
};
const getReportsProducts = (reportsPro) => {
  return {
    type: types.GET_ALL_REPORTS_PRODUCTS,
    payload: reportsPro,
  };
};

const downloadFile = () => {
  return {
    type: types.DOWNLOAD_VISITES_EXCEL,
  };
};

const downloadFilePro = () => {
  return {
    type: types.DOWNLOAD_VISITES_PRODUCTS,
  };
};
const emptyGeneral = (empty, typee) => {
  return {
    type: typee,
    payload: empty,
  };
};
const downloadGeneralReports = () => {
  return {
    type: types.EXPORT_GENERAL_REPORTS,
  };
};

const getGeneralExReports = (generalExReport) => {
  return {
    type: types.GET_GENERALEX_REPORTS,
    payload: generalExReport,
  };
};

const downloadProductsMonthlyExReports = () => {
  return {
    type: types.EXPORT_MONTHLY_PRODUCTS,
  };
};
const downloadMedicalRepTargetExReports = () => {
  return {
    type: types.EXPORT_MEDICAL_REP_TARGET,
  };
};
const downloadGeneralTargetGroup = () => {
  return {
    type: types.EXPORT_GENERAL_TARGET_GROUP,
  };
};
const downloadOrdersReq = () => {
  return {
    type: types.EXPORT_ORDERS_REQS,
  };
};
const downloadObjections = () => {
  return {
    type: types.EXPORT_OBJECTIONS_REPORT,
  };
};
const downloadOrders = () => {
  return {
    type: types.EXPORT_ORDERS,
  };
};
const downloadAllVisits = () => {
  return {
    type: types.EXPORT_All_Visits,
  };
};
const downloadTargetGroupVisits = () => {
  return {
    type: types.EXPORT_TARGET_GROUP_VISITS,
  };
};
const downloadTargetFreeSamples = () => {
  return {
    type: types.EXPORT_FREE_SAMPLES,
  };
};
const downloadTargetFreeSamplesTraget = () => {
  return {
    type: types.EXPORT_FREE_SAMPLES_TARGET,
  };
};
const downloadTargetMarketingTools = () => {
  return {
    type: types.EXPORT_FREE_MARKETING_TOOLS,
  };
};
const downloadRepSales = () => {
  return {
    type: types.EXPORT_REP_SALES_REPORT,
  };
};

export const loadReportss = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plan/${id}/visits-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch(getReports(res.data.data.visits));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const loadReportssPro = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plan/${id}/products-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch(getReportsProducts(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const downloadReportss = (id, setloadFile) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadFile(true);
    axios
      .get(`${base_url}api/work-plan/${id}/visits-report/export`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        console.log(res.data.data);
        console.log(res.data.status);
        if (res.data.status == "Success") {
          console.log("nour1");
          const fileURL = `${base_url}${res.data.data}`;
          console.log(fileURL);
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadFile());
          setloadFile(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloadFile(false);
      });
  };
};

export const downloadReportssPro = (id, setloadFile) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadFile(true);
    axios
      .get(`${base_url}api/work-plan/${id}/products-report/export`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadFilePro());
          setloadFile(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloadFile(false);
      });
  };
};

export const emptyReports = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_ALL_REPORTS));
  };
};
export const emptyReportsPro = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_ALL_REPORTS_PRODUCTS));
  };
};

export const loadGeneralReports = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plan/visits-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch(getGeneralReports(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const FilterGeneralReportss = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    console.log("report_type_id" + report_type_id);
    console.log("target_group_type_id" + target_group_type_id);
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/visits-report?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(getGeneralReports(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};

export const FundownloadReportssPro = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    console.log(start_date);
    console.log(end_date);
    console.log(target_group_type_id);
    axios
      .get(
        `${base_url}api/work-plan/visits-report/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadGeneralReports());
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};

export const loadGeneralEXReports = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plan/external-visits-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch(getGeneralExReports(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const FilterGeneralEXReportss = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/external-visits-report?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(getGeneralExReports(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};

export const ProductsMonthlyExcel = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/external-orders/products_monthly_sales_report/statistics/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadProductsMonthlyExReports());
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};

export const loadGeneralTargetGroup = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plan/target-visits-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_GENERAL_TARGET_GROUP));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const FilterGeneralTargetGroup = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/target-visits-report?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[report_type_id]=${report_type_id}&filter[target_group_type_id]=${target_group_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_GENERAL_TARGET_GROUP));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const monthlyOrdersReqs = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/monthly-orders-request-report?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_ORDERS_REQS));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const objectionsReport = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/objections-report?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_OBJECTIONS_REPORT));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const ordersReport = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/monthly-orders-report?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_ORDERS));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const targetGroupReport = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIds = selectedOptions.join(",");

    const targetTypeIds = Array.isArray(target_group_type_id)
      ? target_group_type_id.join(",")
      : target_group_type_id;

    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }

    const url = `${base_url}api/work-plan/target-group-visit?start_date=${start_date}&end_date=${end_date}&target_type_id=${targetTypeIds}&medical_rep_id=${medicalRepIds}&filter[all]=true`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_TARGET_GROUP_VISITES));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const FundownloadTargetGroupVisits = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIds = selectedOptions.join(",");

    const targetTypeIds = Array.isArray(target_group_type_id)
      ? target_group_type_id.join(",")
      : target_group_type_id;

    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/target-group-visit/export?start_date=${start_date}&end_date=${end_date}&target_type_id=${targetTypeIds}&medical_rep_id=${medicalRepIds}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadTargetGroupVisits());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadFreeSamples = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/freeSamples/statistics/excel?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadTargetFreeSamples());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadFreeSamplesTarget = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/freeSamplesWithTargets/statistics/excel?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadTargetFreeSamplesTraget());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadMarketingTools = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/marketingTools/statistics/excel?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadTargetMarketingTools());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadRepSales = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/repSalesReport/statistics/excel?filter[start_date]=${start_date}&filter[end_date]=${end_date}&medical_rep_id=${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadRepSales());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};

export const allVisitsReport = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIds = selectedOptions.join(",");

    const targetTypeIds = Array.isArray(target_group_type_id)
      ? target_group_type_id.join(",")
      : target_group_type_id;

    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/all-visit-report?start_date=${start_date}&end_date=${end_date}&target_type_id=${targetTypeIds}&medical_rep_id=${medicalRepIds}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_ALL_VISITS));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const freeSamplesReport = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/freeSamples/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_FREE_SAMPLES));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const freeSamplesTargetReport = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/freeSamplesWithTargets/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_FREE_SAMPLES_Target));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const marketingToolsReport = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/medical-rep/marketingTools/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_MARKETING_TOOLS));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const productsMonthlySales = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[external-orders][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/external-orders/products_monthly_sales_report/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(get(res.data.data, types.GET_PRODUCTS_MONTHLY_SALES));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const medicalRepTargetReport = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[external-orders][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/external-orders/medical_rep_target/statistics?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        dispatch(get(res.data.data, types.GET_MEDICAL_REP_TARGET));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};

export const FundownloadReportTargetGroup = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    console.log(start_date);
    console.log(end_date);
    console.log(target_group_type_id);
    axios
      .get(
        `${base_url}api/work-plan/target-visits-report/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[report_type_id]=${report_type_id}&filter[target_group_type_id]=${target_group_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadGeneralTargetGroup());
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const medicalRepTargetExcel = (
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const termsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    console.log(start_date);
    console.log(end_date);
    console.log(target_group_type_id);
    axios
      .get(
        `${base_url}api/external-orders/medical_rep_target/statistics/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[report_type_id]=${report_type_id}&filter[target_group_type_id]=${target_group_type_id}&${termsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadMedicalRepTargetExReports());
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
export const FundownloadReportOrdersReqs = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/monthly-orders-request-report/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadOrdersReq());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadReportObjections = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/objections-report/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadObjections());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadReportOrders = (
  start_date,
  end_date,
  selectedOptions,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIdsQuery = selectedOptions
      .map((id, index) => `filter[medicalRepIds][${index}]=${id}`)
      .join("&");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/monthly-orders-request-report/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&${medicalRepIdsQuery}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadOrders());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
export const FundownloadAllVisits = (
  start_date,
  end_date,
  selectedOptions,
  target_group_type_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const medicalRepIds = selectedOptions.join(",");

    const targetTypeIds = Array.isArray(target_group_type_id)
      ? target_group_type_id.join(",")
      : target_group_type_id;

    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/work-plan/all-visit-report/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&target_type_id=${targetTypeIds}&medical_rep_id=${medicalRepIds}&filter[all]=${true}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadAllVisits());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
