import React, { useEffect, useState } from 'react'
import Page_Header from '../General/Page_Header'
import { AbstructVar } from '../General/GeneralComponent/AbstructVar'
import { AbstructLang } from '../General/GeneralComponent/AbstructLang'
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom'
import MultiSelectCheckbox from '../General/GeneralComponent/MultiSelectCheckbox'
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading'
import './Request.css'
import { VariableRequest } from './VariableRequest'
import { postRequest } from '../../Redux/Actions/ActionRequest'
import img from '../../images/send_notification.svg'

function Request() {
    let { dispatch, navigate, langmiamed } = AbstructVar()
    const { translate } = AbstructLang()
    const { LoadMedicalRepCustom } = FunctionsLoading()
    const { medicalRep, loadingmedicalRep } = useSelectorCustom()
    const [selectedOptions, setSelectedOptions] = useState([]);
    const {state , handleChangeRequest , setLoad } = VariableRequest()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const submit = (event) => {
        event.preventDefault();
        dispatch(postRequest(state.title,state.body,selectedOptions,setLoad))
    }
    const goToAllReq = (event) =>{
        event.preventDefault();
        navigate('/all-requests')
    }
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={translate('request')} path={`${translate('home')} / ${translate('request')} `} />
                </main>
                <div className="page-content" >
                    <div className='div_request' dir={langmiamed === 'ar' ? "rtl" :"ltr"}  >
                        <div className='div_request_left' >
                            <img src={img} />
                            {/*<h4 onClick={goToAllReq} > {translate('title_get_request')} </h4>*/}
                        </div>
                        <div className='div_request_right' >
                            <h3>{translate('send-notification')}</h3>
                            <p className={langmiamed == 'ar' ? "textRight" : "textLeft"}  >{translate('title')}</p>
                            <input onChange={(e) => handleChangeRequest(e.target.value, 'title')} />
                            <p  className={langmiamed == 'ar' ? "textRight" : "textLeft"} >{translate('delegates')}</p>
                            <div style={{  marginLeft: '10px', marginBottom: "15px", width: '100%' }} >
                                <MultiSelectCheckbox
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                    testarr={medicalRep}
                                    typeCss={true}
                                    funb={LoadMedicalRepCustom}
                                    load={loadingmedicalRep}
                                    is_width={true}
                                />
                            </div>
                            <p className={langmiamed == 'ar' ? "textRight" : "textLeft"} >{translate('body')}</p>
                            <textarea onChange={(e) => handleChangeRequest(e.target.value, 'body')} />
                            <button onClick={submit} >
                                {
                                    state.Load ? "Loading ..." : `${translate('send')}`
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Request