import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Page_Header from "../General/Page_Header";
import ActionTop from "../General/ActionTop";
import { array_action } from "../../dummyData";
import "./WorkPlans.css";
import AddWorkPlan from "./AddWorkPlan";
import { useNavigate } from "react-router-dom";
import FilterWorkPlans from "./FilterWorkPlans";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWorkPlan,
  loadWorkPlans,
  refreshWorkPlans,
} from "../../Redux/Actions/ActionWorkPlans";
import InnerLoader from "../General/InnerLoader";
import Warning from "../General/Warning_Delete/Warning";
import EditPlan from "./EditPlan";
import SinglePagenation from "../General/Pagination/SinglePagenation";
import { exportFile } from "../General/Notification";
import NoData from "../General/NoData/NoData";
import NoInternet from "../General/NotPermission/NoInternet";
import PopupRefresh from "./PopupRefresh";
import userr from "../../images/new side icon/profile-user.png";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

function WorkPlans() {
  const currentDate = moment();
  const formattedDate = currentDate.format("YYYY-MM-DD");
  const [openInternet, setopenInternet] = useState(false);
  let dispatch = useDispatch();
  const workplans = useSelector((state) => state.work_plans.workplans);
  const reversedWorkplans = [...workplans].reverse();
  const loadingWorkplans = useSelector(
    (state) => state.work_plans.loadingWorkplans
  );
  let navigate = useNavigate();
  let langmiamed = localStorage.getItem("langmiamed");
  const [Load, setLoad] = useState(false);
  const [openRef, setopenRef] = useState(false);
  const [duration, setduration] = useState(null);
  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [arr1, setarr1] = useState([]);
  const [loadDel, setloadDel] = useState(false);
  const [work_plan_id, setwork_plan_id] = useState(false);
  const [all_work_plan_active, setallwork_plan_active] = useState([]);
  const [is_active, setis_active] = useState("active");
  const openAddNew = () => {
    setOpen(true);
  };
  const [deletee, setdeletee] = useState(false);
  const [arrW, setarrW] = useState([]);
  const warning = (row) => {
    setarrW(row);
    setdeletee(true);
  };
  const handleDelete = (id) => {
    dispatch(deleteWorkPlan(id, setdeletee, setloadDel));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("langmiamed"));
    dispatch(loadWorkPlans(navigate, setopenInternet));
  }, []);
  useEffect(() => {
    if (reversedWorkplans.length > 0 && is_active === "active") {
      const avticee = reversedWorkplans.filter(
        (ww) => ww.is_in_service === true
      );
      setallwork_plan_active(avticee);
    } else {
      const unavticee = reversedWorkplans.filter(
        (ww) => ww.is_in_service === false
      );
      setallwork_plan_active(unavticee);
    }
  }, [workplans, is_active]);
  const handleEditClick = (event, arr) => {
    event.preventDefault();
    const formValues = {
      id: arr.id,
      start_date: arr.start_date,
      end_date: arr.end_date,
      note: arr.note,
      days_duration: arr.days_duration,
      medical_rep: arr.medical_rep,
      is_in_service: arr.is_in_service,
      medical_rep_id: arr.medical_rep_id,
      medical_rep_name: arr.medical_rep_name,
    };
    setarr1(formValues);
    setOpenEdit(true);
  };
  const gotoTask = (id) => {
    navigate(`/work-plans/${id}/task`);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const workplanPerPage = 12;
  const pagesVisited = pageNumber * workplanPerPage;
  const displayworkplan = all_work_plan_active.slice(
    pagesVisited,
    pagesVisited + workplanPerPage
  );

  const pageCount = Math.ceil(all_work_plan_active.length / workplanPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const exportToExcel = () => {
    exportFile(all_work_plan_active, "work_plans_data");
  };
  const refreshWork = (num, id) => {
    console.log("nour");
    setduration(num);
    setopenRef(true);
    setwork_plan_id(id);
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={t("work_plans")}
            path={`${t("home")} / ${t("work_plans")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
            FunctionCustum={openAddNew}
            is_view={true}
          />
          {open && (
            <AddWorkPlan
              t={t}
              langmiamed={langmiamed}
              open={open}
              setOpen={setOpen}
            />
          )}
          <div
            className={`tabsActive ${
              langmiamed === "ar" ? "tabsActiveAr" : "tabsActiveEn"
            }`}
          >
            <span
              className="active_plane_blue"
              onClick={() => setis_active("active")}
            >
              {t("active")}
            </span>
            <span
              className="active_plane_notblue"
              onClick={() => setis_active("unactive")}
            >
              {t("notactive")}
            </span>
          </div>
          <FilterWorkPlans
            navigate={navigate}
            setopenInternet={setopenInternet}
            langmiamed={langmiamed}
            t={t}
          />
          <div className="allworkplan" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingWorkplans == false ? (
              displayworkplan.map((pp) => {
                return (
                  <div
                    key={pp.id}
                    className={`oneworkplan ${
                      langmiamed == "ar" ? "card1_ar" : "card1_en"
                    } `}
                  >
                    {pp.is_in_service === true ? (
                      <span className="active_plane active_plane_blue">
                        {t("active")}
                      </span>
                    ) : (
                      <span className="active_plane active_plane_notblue">
                        {t("notactive")}
                      </span>
                    )}

                    <div className="refresh_plan">
                      <i className="las la-sync"></i>
                    </div>
                    <div className="head_plan" onClick={() => gotoTask(pp.id)}>
                      <i className="las la-calendar cal_work"></i>
                      <div className="head_plan">
                        <span style={{ fontFamily: CheckFont(pp.start_date) }}>
                          {pp.start_date}{" "}
                        </span>
                        <i
                          className={
                            langmiamed == "ar"
                              ? "las la-long-arrow-alt-left"
                              : "las la-long-arrow-alt-right"
                          }
                        ></i>
                        <span style={{ fontFamily: CheckFont(pp.end_date) }}>
                          {pp.end_date}{" "}
                        </span>
                      </div>
                    </div>
                    <p
                      style={{ fontFamily: CheckFont(pp.note) }}
                      className={`note ${
                        langmiamed == "ar" ? "textRight" : ""
                      } `}
                      onClick={() => gotoTask(pp.id)}
                    >
                      {pp.note}
                    </p>
                    <div className="plan_day" onClick={() => gotoTask(pp.id)}>
                      {t("for")}{" "}
                      <span
                        style={{ fontFamily: CheckFont(pp.days_duration) }}
                        className={`plan_day_num ${
                          langmiamed == "ar"
                            ? "margin_right_20"
                            : "margin_left_20"
                        } `}
                      >
                        {pp.days_duration} {t("days")}
                      </span>
                    </div>
                    <div className="row_plann">
                      <div className="delegate_name">
                        <img
                          style={{ marginTop: "0px" }}
                          src={userr}
                          className={
                            langmiamed == "ar"
                              ? "margin_left_10"
                              : "margin_right_10"
                          }
                        />
                        <span
                          style={{ fontFamily: CheckFont(pp.medical_rep_name) }}
                        >
                          {pp.medical_rep_name}
                        </span>
                      </div>
                      <div className="action_plan">
                        <i
                          className="las la-pen icon_edit_table"
                          onClick={(event) => handleEditClick(event, pp)}
                        ></i>
                        <i
                          className="las la-times-circle  icon_del_table"
                          onClick={() => warning(pp)}
                        ></i>
                      </div>
                    </div>
                    {pp.is_in_service === true &&
                    pp.end_date <= formattedDate ? (
                      <button
                        className="refresh_plan refresh_plan_backy"
                        onClick={() => refreshWork(pp.days_duration, pp.id)}
                      >
                        {Load ? (
                          <i className="las la-spinner"></i>
                        ) : (
                          <i className="las la-sync"></i>
                        )}
                      </button>
                    ) : (
                      <div className="refresh_plan refresh_plan_backN">
                        <i className="las la-sync"></i>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="div_loadd">
                <InnerLoader />
              </div>
            )}
            {loadingWorkplans == false && workplans.length == 0 && <NoData />}
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
          </div>
        </div>
      </div>
      {deletee == true ? (
        <Warning
          loadDel={loadDel}
          arr={arrW}
          open={deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {openEdit && (
        <EditPlan arr={arr1} open1={openEdit} setOpen={setOpenEdit} />
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
      {openRef && (
        <PopupRefresh
          work_plan_id={work_plan_id}
          navigate={navigate}
          setopenInternet={setopenInternet}
          duration={duration}
          t={t}
          langmiamed={langmiamed}
          open1={openRef}
          setOpen={setopenRef}
        />
      )}
    </>
  );
}

export default WorkPlans;
