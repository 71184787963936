import React,{useState} from 'react'
import InnerLoader from '../General/InnerLoader';
import { VariableAssign } from './VariableAssign';
import { assignMedicalRep } from '../../Redux/Actions/ActionMedicalRep';
import FilterAssign from './FilterAssign';
import loc from '../../images/icons/location_icon.svg'
import people from '../../images/icon_sidebar/people.png'
import medd from '../../images/icons/med.png'
import { CheckFont } from '../General/GeneralComponent/CheckLang';

function AssignComponent({ langmiamed, translate, target_group, loadingTargetGroup ,dispatch,medical_rep_id}) {
    const { state, setLoad, handleCheckboxChange, handleSelectAll } = VariableAssign()
    const [openFitler, setOpenFilter] = useState(false)
    const assign = () => {
        console.log(medical_rep_id)
        console.log(state.checkedIds)
        dispatch(assignMedicalRep(medical_rep_id, state.checkedIds, setLoad))
    }
    return (
        <>
            <div className='div_checkbox' >
                <div className='div_assign' >
                    <h5 className={langmiamed == 'ar' ? "textRight" : ""}>{translate('target_group')}</h5>
                    <div className='action_assign' >
                        <span className={`btn_assign ${langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"}`} onClick={assign} >
                            {
                                state.Load ? "Loading ..." : `${translate('assign')}`
                            }
                        </span>
                        <span className='btn_assign' onClick={() => setOpenFilter(true)} >{translate('filter')}</span>
                    </div>
                </div>
                <div className='name_medRep' key="selectAll">
                    <input className={langmiamed == 'ar' ? "margin_left_20" : "margin_right_20"} type='checkbox' onChange={(event) => handleSelectAll(event, "checkedIds", "selectAll", target_group)} checked={state.selectAll} />
                    <span>{translate('selectall')}</span>
                </div>
                {
                    loadingTargetGroup == false ? (
                        target_group.map((tt) => {
                            const isChecked = state.selectAll || state.checkedIds.includes(tt.id);
                            return (
                                <div className='name_medRep' key={tt.id} >
                                    <input className={langmiamed == 'ar' ? "margin_left_20" : "margin_right_20"} value={tt.id} type='checkbox' onChange={(event) => handleCheckboxChange(event, 'checkedIds')} checked={isChecked} />
                                    <div className='colcol' >
                                        <div className='bothcol' >
                                            <img src={people} />
                                            <span style={{fontFamily: CheckFont(tt.name)}} className={langmiamed == "ar" ? "margin_right_10" : "margin_left_20"} >{tt.name}</span>
                                        </div>
                                        {
                                            tt.address != null && (
                                                <div className='bothcol' >
                                                    <img src={loc} />

                                                    <span style={{fontFamily: CheckFont(tt.address)}} className={langmiamed == "ar" ? "margin_right_10" : "margin_left_20"} >{tt.address}</span>

                                                </div>
                                            )
                                        }
                                        {
                                            tt.medical_rep_name != null && (
                                                <div className='bothcol' >
                                                    <img src={medd} />

                                                    <span style={{fontFamily: CheckFont(tt.medical_rep_name)}} className={langmiamed == "ar" ? "margin_right_10" : "margin_left_20"} >{tt.medical_rep_name}</span>

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    ) : <div className='loadSetting' ><InnerLoader /></div>
                }
            </div>
            {
                openFitler && (
                    <FilterAssign t={translate} open={openFitler} setOpen={setOpenFilter} />
                )
            }
        </>
    )
}

export default AssignComponent