import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelectCheckbox from "../../General/GeneralComponent/MultiSelectCheckbox";
import { FunctionsLoading } from "./FunctionsLoading";

import { AbstructVar } from "../../General/GeneralComponent/AbstructVar";
import {
  FundownloadAllVisits,
  FundownloadFreeSamples,
  FundownloadFreeSamplesTarget,
  FundownloadMarketingTools,
  FundownloadReportObjections,
  FundownloadReportOrders,
  FundownloadReportOrdersReqs,
  FundownloadReportTargetGroup,
  FundownloadTargetGroupVisits,
  allVisitsReport,
  freeSamplesReport,
  freeSamplesTargetReport,
  marketingToolsReport,
  monthlyOrdersReqs,
  objectionsReport,
  ordersReport,
  productsMonthlySales,
  medicalRepTargetReport,
  targetGroupReport,
  ProductsMonthlyExcel,
  medicalRepTargetExcel,
} from "../../../Redux/Actions/ActionReport";
import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
import { VariableStatistics } from "./VariableStatistics";
import MultiSelectForReports from "../../GeneralReports/MultiSelectForReports";
import { useEffect } from "react";
import { format } from "date-fns";
function FilterStatistics({ t, langmiamed, params, excel }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let { dispatch } = AbstructVar();
  const { LoadTargetTypeCustom, LoadMedicalRepCustom, LoadReportTypeCustom } =
    FunctionsLoading();

  useEffect(() => {
    LoadReportTypeCustom();
    LoadTargetTypeCustom();
  }, []);
  const {
    target_types,
    loadingtarget_type,
    medicalRep,
    loadingmedicalRep,
    reportType,
    loadingReportType,
  } = useSelectorCustom();
  const {
    state,
    setState,
    handleChangeStatistics,
    setLoad,
    setIsFilter,
    setLoadExport,
  } = VariableStatistics();
  const [selectedOptions, setSelectedOptions] = useState([]);

  // const handleChange = (range) => {
  //   const [startDatee, endDatee] = range;
  //   setState((prevState) => ({
  //     ...prevState,
  //     startDate: startDatee,
  //     endDate: endDatee,
  //   }));
  // };
  const handleDateChange = (date, field, setState) => {
    const formattedDate = format(date, "yyyy-MM-dd", {
      timeZone: "yourTimeZoneHere",
    });
    setState((prevState) => ({
      ...prevState,
      [field]: formattedDate,
    }));
  };

  const handleChangeFirstDate = (date) => {
    handleDateChange(date, "startDate", setState);
    setStartDate(date);
  };

  const handleChangeSecDate = (date) => {
    handleDateChange(date, "endDate", setState);
    setEndDate(date);
  };
  const filter = (event) => {
    event.preventDefault();
    switch (params) {
      case "visits_frequeny_report":
        setIsFilter(true);
        dispatch(
          targetGroupReport(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "target_visits_report":
        setIsFilter(true);
        dispatch(
          allVisitsReport(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "free_samples_summarized_report":
        setIsFilter(true);
        dispatch(
          freeSamplesReport(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "free_samples_detailed_report":
        setIsFilter(true);
        dispatch(
          freeSamplesTargetReport(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "marketing_tools_report":
        setIsFilter(true);
        dispatch(
          marketingToolsReport(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoad
          )
        );
        break;
      case "monthly_orders_request_report":
        setIsFilter(true);
        dispatch(
          monthlyOrdersReqs(
            state.startDate,
            state.endDate,
            state.target_type_id,
            state.report_type_id,
            selectedOptions,
            setLoad
          )
        );

        break;
      case "objection_rebort":
        setIsFilter(true);
        dispatch(
          objectionsReport(
            state.startDate,
            state.endDate,
            selectedOptions,
            setLoad
          )
        );
        break;
      case "monthly_orders_report":
        setIsFilter(true);
        dispatch(
          ordersReport(state.startDate, state.endDate, selectedOptions, setLoad)
        );
        break;
      case "products_monthly_salesReport":
        setIsFilter(true);
        dispatch(
          productsMonthlySales(
            state.startDate,
            state.endDate,
            selectedOptions,

            setLoad
          )
        );
        break;
      case "medical_rep_target_report":
        setIsFilter(true);
        dispatch(
          medicalRepTargetReport(
            state.startDate,
            state.endDate,
            selectedOptions,

            setLoad
          )
        );
        break;
      default:
        break;
    }
  };
  const exportToExcel = (event) => {
    event.preventDefault();

    switch (excel) {
      case "visits_frequeny_report":
        dispatch(
          FundownloadTargetGroupVisits(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "target_visits_report":
        dispatch(
          FundownloadAllVisits(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "free_samples_summarized_report":
        dispatch(
          FundownloadFreeSamples(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoadExport
          )
        );
        break;
      case "free_samples_detailed_report":
        dispatch(
          FundownloadFreeSamplesTarget(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoadExport
          )
        );
        break;

      case "marketing_tools_report":
        dispatch(
          FundownloadMarketingTools(
            state.startDate,
            state.endDate,
            selectedOptions,
            state.target_type_id,
            setLoadExport
          )
        );
        break;

      case "monthly_orders_request_report":
        dispatch(
          FundownloadReportOrdersReqs(
            state.startDate,
            state.endDate,
            selectedOptions,
            setLoadExport
          )
        );
        break;

      case "objection_rebort":
        dispatch(
          FundownloadReportObjections(
            state.startDate,
            state.endDate,
            selectedOptions,
            setLoadExport
          )
        );
        break;
      case "monthly_orders_report":
        dispatch(
          FundownloadReportOrders(
            state.startDate,
            state.endDate,
            selectedOptions,
            setLoadExport
          )
        );
        break;

      case "products_monthly_salesReport":
        dispatch(
          ProductsMonthlyExcel(
            state.startDate,
            state.endDate,
            state.target_type_id,
            state.report_type_id,
            selectedOptions,
            setLoadExport
          )
        );
        break;
      case "medical_rep_target_report":
        dispatch(
          medicalRepTargetExcel(
            state.startDate,
            state.endDate,
            state.target_type_id,
            state.report_type_id,
            selectedOptions,
            setLoadExport
          )
        );
        break;

      default:
        break;
    }
  };

  //   const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  //   useEffect(() => {
  //     setSelectedOptionsName(reportType);
  //   }, [reportType]);

  //   const toggleOption = (optionId, optionName) => {
  //     setSelectedOptions((prevOptions) => {
  //       if (prevOptions.includes(optionId)) {
  //         return prevOptions.filter((opt) => opt !== optionId);
  //       } else {
  //         return [...prevOptions, optionId];
  //       }
  //     });
  //     setSelectedOptionsName((prevOptions) => {
  //       if (prevOptions.includes(optionName)) {
  //         return prevOptions.filter((opt) => opt !== optionName);
  //       } else {
  //         return [...prevOptions, optionName];
  //       }
  //     });
  //   };

  return (
    <div className="newFilterrr" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
      <div className="d-flex gap-2">
        <div className="oneFilternew">
          <DatePicker
            // selected={state.startDate}
            selected={startDate}
            onChange={handleChangeFirstDate}
            // startDate={state.startDate}
            // endDate={state.endDate}
            // selectsRange
            placeholderText={t("selectFirstDate")}
            className="date_inn"
          />
        </div>
        <div className="oneFilternew">
          <DatePicker
            selected={endDate}
            // selected={state.startDate}
            onChange={handleChangeSecDate}
            // startDate={state.startDate}
            // endDate={state.endDate}
            // selectsRange
            placeholderText={t("selectSecDate")}
            className="date_inn"
          />
        </div>
      </div>

      {/* {(params === "target_group_visits" || params === "target_visits_report") && (
        <div className="oneFilternew">
          <SelectWithSearch
            load={loadingtarget_type}
            typeCss={true}
            title={t("target_type")}
            array={target_types}
            setId={(value) => handleChangeStatistics(value, "target_type_id")}
            funb={LoadTargetTypeCustom}
            tt={true}
          />
        </div>
      )} */}

      {/* <div className="oneFilternew">
        <SelectWithSearch
          load={loadingReportType}
          typeCss={true}
          title={t("reportType")}
          array={reportType}
          setId={(value) => handleChangeStatistics(value, "report_type_id")}
          funb={LoadReportTypeCustom}
          tt={true}
        />
      </div> */}
      <div className="oneFilternew oneFilternew2">
        <MultiSelectCheckbox
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          testarr={medicalRep}
          typeCss={true}
          funb={LoadMedicalRepCustom}
          load={loadingmedicalRep}
        />
      </div>
      {/* target type */}

      <div className="oneFilternew">
        {(params === "target_group_visits" ||
          params === "target_visits_report" ||
          params === "visits_frequeny_report") && (
          <MultiSelectForReports
            target_types={target_types}
            setReportsIds={(value) =>
              handleChangeStatistics(value, "target_type_id")
            }
          />
        )}
      </div>

      <button className="targetfilter_button" onClick={filter}>
        {state.Load ? `${t("Loading ...")}` : `${t("filter")}`}
      </button>
      {state.isFilter && (
        <button className="targetfilter_button" onClick={exportToExcel}>
          {state.LoadExport ? `${t("Loading ...")}` : `${t("excel")}`}
        </button>
      )}
    </div>
  );
}

export default FilterStatistics;
