import React, { useState, useEffect } from 'react'
import user from '../../images/user.png'
import './Layout.css'
import useClickOutside from '../../useClickOutside'
import { useSelector } from "react-redux";
import { AuthLogout, loadProfile } from '../../Redux/Actions/ActionAuth';
import { messaging, onMessageListener } from '../../firebase';
import Notify from './Notify'
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import { CheckFont } from '../General/GeneralComponent/CheckLang';
import { toast } from 'react-toastify'
import logo from '../../images/favicon.png'

function Navbar() {
    let { dispatch, navigate, langmiamed } = AbstructVar()
    const profile = useSelector(state => state.auth.profile)
    const { translate, i18n } = AbstructLang();
    const [langPop, setlangPop] = useState(false);
    const [load, setLoad] = useState(false)
    const [notify1, setNotify] = useState(false);
    const [isNewNotification, setIsNewNotification] = useState(false);
    let domNode = useClickOutside(() => {
        setlangPop(false);
    });

    useEffect(() => {
        dispatch(loadProfile(navigate))
    }, [load]);
    useEffect(() => {
        console.log(messaging)
        onMessageListener().then((payload) => {
            console.log('Received a new FCM message:', payload);
            console.log(payload)
            setIsNewNotification(true)
            toast(
                <div dir='rtl' style={{ display: 'flex', alignItems: 'center', padding: '12px', Width: '600px', justifyContent: 'space-between', borderRadius: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                        <div>
                            <strong style={{ fontSize: '18px',textAlign: "right" }}>{payload?.notification?.title}</strong>
                            <p style={{ fontSize: '14px', margin: '4px 0 0',textAlign: "right" }}>{payload?.notification?.body}</p>
                        </div>
                    </div>
                </div>, { position: toast.POSITION.TOP_RIGHT,autoClose: false });
        });
    }, [messaging]);
    const Logout = event => {
        event.preventDefault();
        dispatch(AuthLogout(navigate, setLoad))
    }
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <header className={langmiamed == "ar" ? "header_ar" : "header_en"}>
                    <div className="header-content" dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                        <label htmlFor="menu-toggle">
                            <span className="las la-bars"></span>
                        </label>

                        <div className="header-menu">
                            <label htmlFor="">
                                <span className="las la-language" onClick={() => setlangPop(true)} ></span>
                            </label>
                            {langPop && (
                                <div className={langmiamed == "ar" ? "lang langL" : "lang langR"} ref={domNode} >
                                    <span
                                        className={langmiamed == "ar" ? "textRight" : ""}
                                        onClick={() => {
                                            i18n.changeLanguage("en");
                                            console.log(i18n.language)
                                            localStorage.setItem('langmiamed', i18n.language);
                                        }}
                                    >
                                        {translate('english')}
                                    </span>
                                    <span
                                        className={langmiamed == "ar" ? "textRight" : ""}
                                        onClick={() => {
                                            i18n.changeLanguage("ar");
                                            console.log(i18n.language)
                                            localStorage.setItem('langmiamed', i18n.language);
                                        }}
                                    >
                                        {translate('arabic')}
                                    </span>
                                </div>
                            )}
                            <div className='user_info' >
                                <span style={{ fontFamily: CheckFont(profile.email) }} >{profile.email}</span>
                                <span style={{ fontFamily: CheckFont(profile.role) }} >{profile.role}</span>
                            </div>

                            <div className="notify-icon" onClick={() => { setNotify(true); setIsNewNotification(false) }}>
                                {
                                    isNewNotification && (
                                        <div class="ring-container">
                                            <div class="ringring"></div>
                                            <div class="circle"></div>
                                        </div>
                                    )
                                }
                                <span className="las la-bell" ></span>
                                {
                                    notify1 && (
                                        <Notify notify={notify1} setNotify={setNotify} langmiamed={langmiamed} />
                                    )
                                }
                            </div>

                            <div className="user">
                                <div className="bg-img" style={{ backgroundImage: `url(${user})` }} ></div>

                                <span className="las la-power-off"></span>
                                <span style={{ fontFamily: CheckFont('Logout') }} className='logoutt' onClick={Logout} >
                                    {
                                        load ? "Loading ..." : "Logout"
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </header>

            </div>

        </>
    )
}

export default Navbar