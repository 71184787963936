import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const getWorkFlow = (workflow) => {
  return {
    type: types.GET_WORK_FLOW,
    payload: workflow,
  };
};
const getOneWorkFlow = (oneWorkflow) => {
  return {
    type: types.GET_ONE_WORKFLOW,
    payload: oneWorkflow,
  };
};

const addWorkFlow = () => {
  return {
    type: types.ADD_WORK_FLOW,
  };
};

const delWorkFlow = () => {
  return {
    type: types.DELETE_WORK_FLOW,
  };
};
const editWorkFlow = () => {
  return {
    type: types.EDIT_WORK_FLOW,
  };
};

export const loadworkFlow = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-flows`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(getWorkFlow(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const loadOneworkFlow = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-flows/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(getOneWorkFlow(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const postWorkFlow = (name, description, status, city_id, setLoad , setOpen) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/work-flows`,
        {
          name,
          description,
          status,
          city_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(addWorkFlow());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadworkFlow());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const deleteWorkFlow = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/work-flows/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delWorkFlow());
          dispatch(loadworkFlow());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setdeletee(false);
        setloadDel(false);
        notifyError(err.response.data.message);
      });
  };
};

export const putWorkFlow = (
  id,
  name,
  description,
  status,
  city_id,
  setwork_flow_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/work-flows/${id}`,
        {
          name,
          description,
          status,
          city_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(editWorkFlow());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadworkFlow());
          setLoad(false);
          setwork_flow_id(null);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setwork_flow_id(null);
        setLoad(false);
      });
  };
};
