import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";

const addMeasurement = () => {
    return ({
        type: types.ADD_MEASUREMENT
    })
}
const getMeasuement = (measurement) => {
    return ({
        type: types.GET_MEASUREMENT,
        payload: measurement
    })
}
const delMeasuement  = () => {
    return ({
        type: types.DELETE_MEASUREMENT
    })
}

const addProductCat = () => {
    return ({
        type: types.ADD_PRODUCT_CAT
    })
}
const getProductCat = (productCat) => {
    return ({
        type: types.GET_PRODUCT_CAT,
        payload: productCat
    })
}
const delProductCat  = () => {
    return ({
        type: types.DELETE_PRODUCT_CAT
    })
}

const editProductCat  = () => {
    return ({
        type: types.EDIT_PRODUCT_CAT
    })
}

const getspecByClass = (specilizationByClass) => {
    return ({
        type: types.GET_SPECIALIZATIONS_BY_CLASSIFICATIONS,
        payload: specilizationByClass
    })
}
const getClassBySpec = (classificationByspec) => {
    return ({
        type: types.GET_CLASSIFICATIONS_BY_SPECIALIZATIONS,
        payload: classificationByspec
    })
}
const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}

const link = () => {
    return ({
        type: types.LINK_CLASSIFICATIONS_WITH_SPECIALIZATIONS
    })
}

const declassfiy  = () => {
    return ({
        type: types.DECLASSIFY
    })
}

export const loadMeasurements = () => {
    return function (dispatch) {
        let langmiamed = localStorage.getItem('langmiamed');
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/measurement-units`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                "lang": langmiamed
            }
        }).then((res) => {
            console.log(res);
            dispatch(getMeasuement(res.data.data))
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const postMeasurement = (name,setCreateView1,setLoadUnit) => {
    return function (dispatch) {
        setLoadUnit(true)
        console.log(name)
        let token = localStorage.getItem("tokenMiamed");
        axios.post(`${base_url}api/measurement-units`, {
            name
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(addMeasurement());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadMeasurements())
                setCreateView1('choose')
                setLoadUnit(false)
            }
        }).catch((err) => {
            console.log(err)
            notifyError(err.response.data.message)
            setLoadUnit(false)
        })
    }
}
export const deleteMeasurement = (id,setLoadUnit) => {
    return function (dispatch) {
        setLoadUnit(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.delete(`${base_url}api/measurement-units/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(delMeasuement());
                dispatch(loadMeasurements());
                setLoadUnit(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoadUnit(false)
            notifyError(err.response.data.message)
        })
    }
}

export const loadProductCat = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        let langmiamed = localStorage.getItem('langmiamed');
        axios.get(`${base_url}api/product-categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                "lang": langmiamed
            }
        }).then((res) => {
            console.log(res);
            dispatch(getProductCat(res.data.data))
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const postProductCat = (name,/*setCreateView*/font_family,setLoadCat) => {
    return function (dispatch) {
        setLoadCat(true)
        console.log(name)
        let token = localStorage.getItem("tokenMiamed");
        axios.post(`${base_url}api/product-categories`, {
            name,font_family
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(addProductCat());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadProductCat())
                //setCreateView('choose')
                setLoadCat(false)
            }
        }).catch((err) => {
            console.log(err)
            notifyError(err.response.data.message)
            setLoadCat(false)
        })
    }
}
export const deleteProductCat = (id,setLoadCat) => {
    return function (dispatch) {
        setLoadCat(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.delete(`${base_url}api/product-categories/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(delProductCat());
                dispatch(loadProductCat());
                setLoadCat(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoadCat(false)
            notifyError(err.response.data.message)
        })
    }
}

export const putProductCat = (id,name,font_family,setLoad,setproduct_cat_id) => {
    return function (dispatch) {
        setLoad(true)
        console.log(name)
        let token = localStorage.getItem("tokenMiamed");
        axios.put(`${base_url}api/product-categories/${id}`, {
            name,font_family
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(editProductCat());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadProductCat())
                setLoad(false)
                setproduct_cat_id(null)
            }
        }).catch((err) => {
            console.log(err)
            notifyError(err.response.data.message)
            setLoad(false)
            setproduct_cat_id(null)
        })
    }
}

export const loadSpecByClass = (id) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/specialization-classifications/specializations-by-classification/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getspecByClass(res.data.data))
        }).catch((err) => {
            console.log(err)
        })
    }
}
export const emptySpec = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_SPECIALIZATIONS_BY_CLASSIFICATIONS ))
    }
}

export const loadClassBySpec = (id) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/specialization-classifications/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getClassBySpec(res.data.data))
        }).catch((err) => {
            console.log(err)
        })
    }
}
export const emptyClass = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_CLASSIFICATIONS_BY_SPECIALIZATIONS ))
    }
}

export const linkSpecWithClass = (specialization_id,classification_id,compare_type,number_of_patients_min,number_of_patients_max,setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        console.log("specialization_id "+specialization_id)
        console.log("classification_id "+classification_id)
        console.log("compare_type "+compare_type)
        console.log("number_of_patients_min "+number_of_patients_min)
        console.log("number_of_patients_max "+number_of_patients_max)
        axios.post(`${base_url}api/specialization-classifications/assign`, {
            specialization_id,classification_id,compare_type,number_of_patients_min,number_of_patients_max
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(link());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadClassBySpec(specialization_id))
                dispatch(loadSpecByClass(classification_id))
                setLoad(false)
            }
        }).catch((err) => {
            console.log(err)
            notifyError(err.response.data.message)
            setLoad(false)
        })
    }
}

export const declassfiyy = (id,setLoad,ID1,ID2) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.post(`${base_url}api/specialization-classifications/unassign/${id}`,{}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(declassfiy());
                if(ID1 != null ){
                    dispatch(loadSpecByClass(ID1));
                }
                if(ID2 != null ){
                    dispatch(loadClassBySpec(ID2));
                }
                setLoad(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoad(false)
            notifyError(err.response.data.message)
        })
    }
}