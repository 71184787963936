import {combineReducers} from "redux"
import AuthReducer from "./AuthReducer"
import CountriesReducer from "./CountriesReducer"
import TargetGroupReducer from "./TargetGoupReducer"
import Medical_repReducer from "./Medical_repReducer"
import WorkPlansReducer from "./WorkPlansReducer"
import ConfigProductReducer from "./ConfigProductReducer"
import ProductReducer from "./ProductReducer"
import WorkFlowReducer from "./WorkFlowReducer"
import VacationReducer from "./VacationReducer"
import TaskReducer from "./TaskReducer"
import ExternalVisitesReducer from "./ExternalVisitesReducer"
import ReportTypeReducer from "./ReportTypeReducer"
import RolesReducer from "./RolesReducer"
import ReportReducer from "./ReportReducer"
import StatisticsReducer from "./StatisticsReducer"
import WorkPlan_Reducer from "./WorkPlan_ProductsReducer"
import RequestReducer from "./RequestReducer"
import PoliticsReducer from "./PoliticsReducer"
import GovernorateReducer from "./GovernorateReducer"


export default combineReducers({
    auth: AuthReducer,
    countries: CountriesReducer,
    governorates:GovernorateReducer,
    target: TargetGroupReducer,
    medical_rep: Medical_repReducer,
    work_plans: WorkPlansReducer,
    configProduct: ConfigProductReducer,
    productt: ProductReducer,
    work_floww: WorkFlowReducer,
    vacationn: VacationReducer,
    work_task: TaskReducer,
    externalVisites: ExternalVisitesReducer,
    report_type: ReportTypeReducer,
    roless: RolesReducer,
    reportss: ReportReducer,
    statisticss: StatisticsReducer,
    Product_WorkPlan: WorkPlan_Reducer,
    request: RequestReducer,
    politicss: PoliticsReducer
})