import React, { useEffect, useState } from 'react'
import Page_Header from '../General/Page_Header';
import Table2 from '../Table2/Table2';
import './Country.css'
import AddCountry from './AddCountry';
import AddCity from './Cities/AddCity';
import { deleteCountry, loadCitiesbyCountry, loadCountries } from '../../Redux/Actions/ActionCountries';
import InnerLoader from '../General/InnerLoader';
import Warning from '../General/Warning_Delete/Warning';
import EditCountry from './EditCountry';
import ShowCities from './Cities/ShowCities';
import NoInternet from '../General/NotPermission/NoInternet';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import { ColumnArrayLocation, VariableCountry } from './VariableCountry';
import { DeleteComponent } from '../General/GeneralComponent/DeleteComponent';

function Country() {
    const [openInternet, setopenInternet] = useState(false)
    let { dispatch, langmiamed } = AbstructVar()
    let { translate } = AbstructLang()
    const { countriess, loadingCountries } = useSelectorCustom()
    const { state, setState, handleFilter, setRecords, handleEditCountry, seteditCountry, setaddCity, setaddCountry, setis_search } = VariableCountry()
    const { stateD, warning, setdeletee, setloadDel } = DeleteComponent()
    const [loadcityy, setloadcityy] = useState(false);
    const handleDelete = (id) => {
        dispatch(deleteCountry(id, setdeletee, setloadDel))
    }
    const storeId = (arr) => {
        setState((prevState) => ({
            ...prevState,
            activeView: arr.id,
            nameCountry: arr.name
        }));
        dispatch(loadCitiesbyCountry(arr.id, setloadcityy))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadCountries(setopenInternet))
    }, []);
    useEffect(() => {
        setRecords(countriess.reverse())
    }, [countriess])
    const handleAddCity = (event, id) => {
        event.preventDefault();
        setState((prevState) => ({
            ...prevState,
            addcity_id: id,
            addCity: true
        }));
    }
    const { columns_country } = ColumnArrayLocation(state, translate, handleEditCountry, warning, handleAddCity, storeId)
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={`${translate('country_and_city')}`} path={`${translate('home')} / ${translate('country_and_city')} `} />
                </main>
                <div className="page-content" >
                    <div className='bothTable' dir={langmiamed == "ar" ? "rtl" : "ltr"}  >
                        <div className='table_country' >
                            <div className='div_country' >
                                <div>
                                    <i className="las la-city"></i>
                                    <h4 className={langmiamed == 'ar' ? "margin_right_10" : "margin_left_10"} >{translate('countries')}</h4>
                                </div>
                                <div>
                                    <i class="las la-search" style={{ margin: "0px 20px", cursor: "pointer" }} onClick={() => setis_search(!state.is_search)} ></i>
                                    <button onClick={() => setaddCountry(true)} >{translate('addNew')}</button>
                                </div>
                            </div>
                            {
                                state.is_search && (
                                    <div className='div_inputSearch' >
                                        <input className='inputSearch' placeholder={translate('searchCountry')} onChange={(e) => handleFilter(e, countriess)} />
                                    </div>
                                )
                            }
                            {
                                loadingCountries == false ? (
                                    <Table2 col={columns_country} roo={state.records} />
                                ) : <InnerLoader />
                            }
                        </div>
                        <ShowCities setloadcityy={setloadcityy} loadcityy={loadcityy} id={state.activeView} name={state.nameCountry} t={translate} langmiamed={langmiamed} />
                    </div>
                </div>
            </div>
            {
                state.editCountry && (
                    <EditCountry arr={state.arr2} open={state.editCountry} setOpen={seteditCountry} t={translate} langmiamed={langmiamed} />
                )
            }
            {
                state.addCountry && (
                    <AddCountry open={state.addCountry} setOpen={setaddCountry} t={translate} langmiamed={langmiamed} />
                )
            }
            {
                state.addCity && (
                    <AddCity show_id={state.activeView} id={state.addcity_id} open={state.addCity} setOpen={setaddCity} t={translate} langmiamed={langmiamed} />
                )
            }
            {
                stateD.deletee == true ? (
                    <Warning loadDel={stateD.loadDel} arr={stateD.arrW} open={stateD.deletee} setOpen={setdeletee} handleDelete={handleDelete} />
                ) : <></>
            }
            {
                openInternet && (
                    <NoInternet open={openInternet} setOpen={setopenInternet} />
                )
            }
        </>
    )
}

export default Country
