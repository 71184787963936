import { useState } from "react"

export const VariableProductCategory = () => {
    const [state, setState] = useState({
        name: "",
        font_family: "",
        Load: false,
        LoadCat: false,
        IDCat: "",
        product_cat_id: "",
        arr1: []
    })
    const setLoad = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setLoadCat = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            LoadCat: value,
        }));
    };
    const setIDCat = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            IDCat: value,
        }));
    };
    const setproduct_cat_id = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            product_cat_id: value,
        }));
    };
    const handleEditProductCategory = (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            name: arr.name,
            font_family: arr.font_family
        };
        setState((prevState) => ({
            ...prevState,
            product_cat_id: arr.id,
            arr1: formValues
        }));
    }
    const handleChangeProductCategory = (value, variableName) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };

    return {
        ...state,
        state,
        setState,
        handleChangeProductCategory,
        handleEditProductCategory,
        setLoad,
        setLoadCat,
        setIDCat,
        setproduct_cat_id
    }
}