import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { deleteCity, loadCities } from '../../../Redux/Actions/ActionCountries';
import EditCity from './EditCity';
import InnerLoader from '../../General/InnerLoader';
import Warning from '../../General/Warning_Delete/Warning';
import { DeleteComponent } from '../../General/GeneralComponent/DeleteComponent';
import { VariableCountry } from '../VariableCountry';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function ShowCities({ setloadcityy, loadcityy, id, name, t, langmiamed }) {
    let dispatch = useDispatch();
    const { state, setRecords, setcity_id, setis_search, handleEditCity, handleFilter } = VariableCountry()
    const { stateD, warning, setdeletee, setloadDel } = DeleteComponent()
    const { cities, loadingCities } = useSelectorCustom()
    useEffect(() => {
        if (id == null) {
            dispatch(loadCities())
            console.log(id)
        }
    }, [])
    useEffect(() => {
        setRecords(cities.reverse())
    }, [cities])
    const handleDelete = (city_id) => {
        dispatch(deleteCity(city_id, setdeletee, setloadDel, id))
    }
    return (
        <>
            <div className='table_city' >
                <div className='div_country' >
                    <div>
                        <i className="las la-city"></i>
                        <h4 className={langmiamed == 'ar' ? "margin_right_10" : "margin_left_10"} >
                            <span style={{ fontFamily: CheckFont(name) }}>{name} </span>
                            / {t('cities')}</h4>
                    </div>
                    <div>
                        <i class="las la-search" style={{ margin: "0px 20px", cursor: "pointer" }} onClick={() => setis_search(!state.is_search)} ></i>
                    </div>
                </div>
                {
                    state.is_search && (
                        <div className='div_inputSearch' >
                            <input className='inputSearch' placeholder={t('searchCity')}  onChange={(e) => handleFilter(e, cities)} />
                        </div>
                    )
                }
                <div className='allcities' >
                    {
                        (loadingCities == false && loadcityy == false) ? (
                            state.records.map((cc) => {
                                return (
                                    <>
                                        {
                                            cc.id === state.city_id ? (
                                                <EditCity setloadcityy={setloadcityy} country_id1={id} arr={state.arr2} setcity_id={setcity_id} />
                                            ) :
                                                <div className='onecity' ket={cc.id} >
                                                    <span style={{ fontFamily: CheckFont(cc.name) }}>{cc.name}</span>
                                                    <div>
                                                        <i className="las la-pen icon_edit_table" onClick={(event) => handleEditCity(event, cc)} ></i>
                                                        <i className="las la-times-circle  icon_del_table" onClick={() => warning(cc)}></i>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                )
                            })) : <InnerLoader />
                    }

                </div>
            </div>
            {
                stateD.deletee == true ? (
                    <Warning loadDel={stateD.loadDel} arr={stateD.arrW} open={stateD.deletee} setOpen={setdeletee} handleDelete={handleDelete} />
                ) : <></>
            }
        </>
    )
}

export default ShowCities