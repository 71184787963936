import React, { useEffect } from "react";
import useClickOutside from "../../useClickOutside";
import Add from "../General/Add";
import { useDispatch } from "react-redux";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { VariableProducts } from "./VariableProducts";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import SelectCreatableWithSearch from "../General/GeneralComponent/SelectCreatableWithSearch";
import { postProduct } from "../../Redux/Actions/ActionProduct";

function AddProduct({ t, langmiamed, setOpen }) {
  let dispatch = useDispatch();
  const { measurement, loadingmeasurement, productCat, loadingproductCat } =
    useSelectorCustom();
  const { state, handleChangeProducts, setLoad } = VariableProducts();
  const {
    LoadProductCatCustom,
    PostUnit_of_measureCustom,
    DeleteUnit_of_measureCustom,
    LoadMeasurementsCustom,
  } = FunctionsLoading();
  useEffect(() => {}, []);
  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  const submit = (event) => {
    event.preventDefault();
    dispatch(
      postProduct(
        state.name,
        state.description,
        state.internal_reference,
        state.product_category_id,
        state.measurement_unit_id,
        state.color,
        state.product_type,
        state.image,
        setLoad,
        setOpen
      )
    );
  };
  return (
    <div
      className={`add_target_group ${
        langmiamed == "ar" ? "add_target_group_ar" : "add_target_group_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <Add
        langmiamed={langmiamed}
        icon="las la-plus-circle"
        title={`${t("addproduct")}`}
      />

      <form className="general_form">
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("name")}</span>
        </div>
        <input onChange={(e) => handleChangeProducts(e.target.value, "name")} />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("description")}</span>
        </div>
        <textarea
          onChange={(e) => handleChangeProducts(e.target.value, "description")}
        />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("productCode")}</span>
        </div>
        <input
          type="text"
          // name="code"
          onChange={(e) =>
            handleChangeProducts(e.target.value, "internal_reference")
          }
        />
        <div className="form_header">
          <i
            className={`las la-hand-pointer ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("categories")}</span>
        </div>
        <SelectWithSearch
          load={loadingproductCat}
          title={t("categories")}
          array={productCat}
          setId={(value) => handleChangeProducts(value, "product_category_id")}
          funb={LoadProductCatCustom}
        />
        <div className="form_header">
          <i
            className={`las la-hand-pointer ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>

          <span>{t("unit")}</span>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <SelectCreatableWithSearch
            load={loadingmeasurement}
            options={measurement}
            title={t("unit")}
            post={PostUnit_of_measureCustom}
            deleteFunction={DeleteUnit_of_measureCustom}
            funb={LoadMeasurementsCustom}
            setIdd={(value) =>
              handleChangeProducts(value, "measurement_unit_id")
            }
            name={state.measurement_unit_name}
            previousID={state.measurement_unit_id}
            typeCss={true}
          />
        </div>
        <SelectWithSearch
          load={loadingmeasurement}
          title={t("unit")}
          array={measurement}
          setId={(value) => handleChangeProducts(value, "measurement_unit_id")}
          funb={LoadMeasurementsCustom}
        />
        {/* <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("product_type")}</span>
        </div>

        <select
          onChange={(e) => handleChangeProducts(e.target.value, "product_type")}
        >
          <option disabled="disabled" selected="selected">
            {t("product_type")}
          </option>
          <option value="marketing_tools">Marketing Tools</option>
          <option value="medicine_samples">Free Samples</option>
        </select> */}

        <div className="form_header">
          <i
            className={`las la-camera-retro ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("color")}</span>
        </div>
        <br></br>
        <input
          type="color"
          onChange={(e) =>
            handleChangeProducts(
              e.target.value ? e.target.value : "#808080",
              "color"
            )
          }
          defaultValue="gray"
        />
        <div className="form_header">
          <i
            className={`las la-camera-retro ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("image")}</span>
        </div>
        <br></br>
        <input
          type="file"
          onChange={(e) => handleChangeProducts(e.target.files[0], "image")}
        />
        <button onClick={submit}>
          {state.Load ? "Loading ..." : `${t("save")}`}
        </button>
      </form>
    </div>
  );
}

export default AddProduct;
