function CheckLang(text) {
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
    return arabicRegex.test(text);
}

function CheckFont(text) {
    let check = CheckLang(text);
    if (check) {
        return 'GE SS TWO';
    } else {
        return 'sans-serif';
    }
}

export { CheckFont };

function CheckArabic(text,fontFamily) {
    console.log(fontFamily)
    let check = CheckLang(text);
    if (check) {
        return 'GE SS TWO';
    }else{
        return fontFamily
    }
}

export { CheckArabic };

