import React, { useEffect, useState } from "react";
import Page_Header from "../General/Page_Header";
import "./GeneralReports.css";
import NoInternet from "../General/NotPermission/NoInternet";
import External_In_Map from "../External_Visites/External_In_Map";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { ColumnArrayReports, VariableReports } from "./VariableReports";
import OrdersReqsReport from "./OrdersReqsReport";
import ObjectionsReport from "./ObjectionsReport";
import OrdersReport from "./OrdersReport";
import TargetGroupReport from "./TargetGroupReport";
import AllVisits from "./AllVisits";
import FreeSamplesReport from "./FreeSamplesReport";
import FreeSamplesTargetReport from "./FreeSamplesTargetReport";
import MarketingToolsReport from "./MarketingToolsReport";
import Slider from "react-slick";
import ProductsSalesReport from "./ProductsSalesReport";
import MedicalRepTargetReport from "./MedicalRepTargetReport";
import Page_General_Statistics from "../Main/CardsMain/Page_General_Statistics";
import { useDispatch } from "react-redux";
import Main from "../Main/Main";
function GeneralReports() {
  const [openInternet, setopenInternet] = useState(false);
  const { navigate, langmiamed } = AbstructVar();
  const { translate } = AbstructLang();
  const dispatch = useDispatch();
  const { state, storeMap, setIsTap, setIsOpen } = VariableReports();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const reportsButtons = [
    { key: "visits_frequeny_report", label: "visits_frequeny_report" },
    { key: "target_visits_report", label: "target_visits_report" },
    {
      key: "free_samples_summarized_report",
      label: "free_samples_summarized_report",
    },
    {
      key: "free_samples_detailed_report",
      label: "free_samples_detailed_report",
    },
    { key: "marketing_tools_report", label: "marketing_tools_report" },
    {
      key: "monthly_orders_request_report",
      label: "monthly_orders_request_report",
    },
    { key: "objection_rebort", label: "objection_rebort" },

    { key: "monthly_orders_report", label: "monthly_orders_report" },
    {
      key: "products_monthly_salesReport",
      label: "products_monthly_salesReport",
    },
    {
      key: "statistics",
      label: "statistics",
    },
    { key: "medical_rep_target_report", label: "medical_rep_target_report" },
  ];

  function SliderArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#5263a400" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: false,
    rtl: true,
    nextArrow: <SliderArrow />,
    prevArrow: <SliderArrow />,
  };
  const ReportComponents = {
    visits_frequeny_report: <TargetGroupReport />,
    target_visits_report: <AllVisits />,
    free_samples_summarized_report: <FreeSamplesReport />,
    free_samples_detailed_report: <FreeSamplesTargetReport />,
    marketing_tools_report: <MarketingToolsReport />,
    monthly_orders_request_report: <OrdersReqsReport />,
    objection_rebort: <ObjectionsReport />,
    monthly_orders_report: <OrdersReport />,
    products_monthly_salesReport: <ProductsSalesReport />,
    medical_rep_target_report: <MedicalRepTargetReport />,
    statistics: (
      <Main
      isDashboardPage = {false}
        langmiamed={langmiamed}
        dispatch={dispatch}
        translate={translate}
      />
    ),
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("reports")}
            path={`${translate("home")} / ${translate("reports")} `}
          />
        </main>
        <div className="page-content">
          <div
            className="choise_task"
            // dir={langmiamed === "ar" ? "rtl" : "ltr"}
          >
            <div className="slider-container" style={{ maxWidth: "100%" }}>
              <Slider {...settings}>
                {reportsButtons.map(({ key, label }) => (
                  <button
                    key={key}
                    className={
                      state.isTap === key
                        ? "active_btn_choise"
                        : "unactive_btn_choise"
                    }
                    onClick={() => {
                      setIsTap(key);
                    }}
                  >
                    {translate(label)}
                  </button>
                ))}
              </Slider>
            </div>
          </div>

          {ReportComponents[state.isTap] && (
            <>
              {React.cloneElement(ReportComponents[state.isTap], {
                langmiamed,
                t: translate,
                setopenInternet,
                navigate,
              })}
            </>
          )}
        </div>
      </div>
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setIsOpen}
          lat={state.lat}
          lng={state.lng}
        />
      )}
    </>
  );
}

export default GeneralReports;
