import React, { useState, useEffect } from "react";
import "./targetOrders.css";
import { useTranslation } from "react-i18next";
import Page_Header from "../General/Page_Header";
import "./Politics.css";
import Select from "react-select";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { loadProduct } from "../../Redux/Actions/ActionProduct";
import { loadMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
import DatePicker from "react-datepicker";
import InnerLoader from "../General/InnerLoader";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { GoPlusCircle } from "react-icons/go";
import { sendTargetOrders } from "../../Redux/Actions/ActionTargetOrders";
import { FaArrowCircleUp, FaArrowCircleDown } from "react-icons/fa";
import { TargetModal } from "./targetOrdersModal";
function TargetOrders() {
  const [t, i18n] = useTranslation();
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const [open, setOpen] = React.useState(false);
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: (i + 1).toString(),
  }));
  // State Declarations
  const [openInternet, setopenInternet] = useState(false);
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState("");
  const [numVisibleColumns, setNumVisibleColumns] = useState(1);
  const [targetOrders, setTargetOrders] = useState([]);
  const [productId, setProductId] = useState("");
  const [load, setLoad] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option.value);
  };
  const { product, loadingproduct, medicalRep, loadingmedicalRep } =
    useSelectorCustom();

  // useEffect hooks
  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("langmiamed"));
    dispatch(loadProduct(navigate, setopenInternet));
    dispatch(loadMedicalRep(navigate, setopenInternet));
  }, []);

  useEffect(() => {
    setProducts(
      product.map((p) => {
        return {
          label: p.name,
          value: p.id,
        };
      })
    );
  }, [product]);

  // Function Declarations
  const handleAddColumns = () =>
    setNumVisibleColumns((prevNumVisibleColumns) => prevNumVisibleColumns + 1);

  const handleInputChange = (e, repId) => {
    const { value } = e.target;
    const existingProductIndex = targetOrders.findIndex(
      (order) => order.productId === productId && order.repId === repId
    );

    if (existingProductIndex !== -1) {
      setTargetOrders((prevOrders) => {
        const updatedOrders = [...prevOrders];
        updatedOrders[existingProductIndex] = {
          ...updatedOrders[existingProductIndex],
          qty: value,
        };
        return updatedOrders;
      });
    } else {
      setTargetOrders((prevOrders) => [
        ...prevOrders,
        { productId, repId, qty: value },
      ]);
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("date", selectedOption);
    formData.append("targetOrders", JSON.stringify(targetOrders));
    dispatch(sendTargetOrders(formData, setOpen, setLoad));
  };

  const productsSelect = (
    <Select
      options={products}
      onChange={(selectedProduct) => {
        const updatedOptions = products.filter(
          (option) => option.value !== selectedProduct.value
        );
        setProducts(updatedOptions);
        setProductId(selectedProduct.value);
      }}
      placeholder={t("Choose a product")}
      styles={{
        placeholder: (provided) => ({
          ...provided,
          color: "gray",
          textAlign: langmiamed === "ar" ? "right" : "left",
        }),
        menu: (provided) => ({
          ...provided,
          textAlign: langmiamed === "ar" ? "right" : "left",
        }),
      }}
    />
  );

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  const [scrollUp, setScrollUp] = useState(true);

  const scroll = () => {
    if (scrollUp) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
    setScrollUp(!scrollUp);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isAtTop = window.scrollY === 0;
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;

      if (isAtTop) {
        setScrollUp(false);
      } else if (isAtBottom) {
        setScrollUp(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {loadingmedicalRep || loadingproduct ? (
        <InnerLoader />
      ) : (
        <div
          className={
            langmiamed === "ar" ? "main-content_ar" : "main-content_en"
          }
        >
          <main>
            <Page_Header
              langmiamed={langmiamed}
              header={t("targetorders")}
              path={`${t("home")} / ${t("targetorders")}`}
            />
            <div className="page-content">
              <div
                className="my-3 date-add-holder"
                data-toggle="tooltip"
                data-placement="top"
                title={t("Add new product")}
              >
                <GoPlusCircle
                  style={{ cursor: "pointer", color: "#5263a4" }}
                  size={24}
                  onClick={handleAddColumns}
                />
                {/* <button
        
                  className="btn btn-primary mt-2"
                >
                  Add More Products
                </button> */}
                <Select
                  value={selectedOption?.value}
                  onChange={handleChange}
                  options={monthOptions}
                  placeholder={t("Select a month")}
                />
                {/* <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  placeholderText={t("selectDate")}
                  className="date-taget-orders"
                  showMonthYearPicker
                  isClearable
                /> */}
                <div className="tooltip fade top" role="tooltip">
                  <div className="tooltip-inner">Add Columns</div>
                </div>
              </div>
              <div
                className="table-container"
                style={{
                  direction: langmiamed === "ar" ? "rtl" : "ltr",
                  width: "1100px",
                }}
              >
                <table
                  className={`table table-bordered ${
                    numVisibleColumns > 3 ? "table-overflow" : ""
                  }`}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>Name</th>
                      {Array.from({ length: numVisibleColumns }, (_, i) => (
                        <th className="column" key={i + 1}>
                          {productsSelect}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {medicalRep.map((rep) => (
                      <tr key={rep.id}>
                        <td>{rep.name}</td>
                        {Array.from({ length: numVisibleColumns }, (_, i) => (
                          <td key={i + 1}>
                            <input
                              type="number"
                              class="form-control"
                              onChange={(e) => handleInputChange(e, rep.id)}
                              placeholder={
                                langmiamed === "ar"
                                  ? `${t("Enter")}${" "}${t("target")}${" "}${
                                      rep.name
                                    }`
                                  : `${t("Enter the target of")}${" "}${" "}${
                                      rep.name
                                    }`
                              }
                              // placeholder={`${t("Enter")}${" "}${t("target")}${" "}${rep.name}`}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="my-2 text-center btn-holder">
                <button onClick={() => setOpen(true)} className="mt-2">
                  {t("Submit")}
                </button>
              </div>
            </div>
          </main>
          <button
            className="scroll"
            style={langmiamed === "ar" ? { left: "30px" } : { right: "20px" }}
            onClick={scroll}
          >
            {scrollUp ? <FaArrowCircleUp /> : <FaArrowCircleDown />}
          </button>
          <TargetModal
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            load={load}
          ></TargetModal>
        </div>
      )}
    </>
  );
}

export default TargetOrders;
