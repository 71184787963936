import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from "react-redux";
import { loadFilterWorkPlans, loadWorkPlans } from '../../Redux/Actions/ActionWorkPlans';
import nofilterr from '../../images/icons/nofilter.png'
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';
import { VariableWorkPlans } from './VariableWorkPlans';

function FilterWorkPlans({ setopenInternet, navigate, langmiamed, t }) {
    let dispatch = useDispatch();
    const { medicalRep, loadingmedicalRep } = useSelectorCustom()
    const { LoadMedicalRepCustom } = FunctionsLoading()
    const { state,setState, handleChangeWorkPlans, setLoad, handleChangeDate } = VariableWorkPlans()
    const [isEmpty , setisEmpty] = useState(false)
    const filter = event => {
        event.preventDefault()
        dispatch(loadFilterWorkPlans(state.medical_rep_id, state.startDate, state.endDate, setLoad))
    };
    const Unfilter = event => {
        event.preventDefault()
        setState((prevState) => ({
            ...prevState,
            medical_rep_id: '',
            startDate: null,
            endDate: null
        }));
        setisEmpty(true)
        dispatch(loadWorkPlans(navigate, setopenInternet))
    };

    return (
        <div className={`targetfilter ${langmiamed == 'ar' ? "padding_right_10" : ""}`} dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            <div className='oneFilter' >
                <SelectWithSearch
                    load={loadingmedicalRep}
                    typeCss={true}
                    title={t('delegates')}
                    array={medicalRep}
                    setId={(value) => handleChangeWorkPlans(value, 'medical_rep_id')}
                    funb={LoadMedicalRepCustom}
                    isEmpty={isEmpty}
                />
            </div>
            <div className='oneFilter' >
                <DatePicker
                    selected={state.startDate}
                    onChange={handleChangeDate}
                    startDate={state.startDate}
                    endDate={state.endDate}
                    selectsRange
                    placeholderText={t('selectDate')}
                    className='date_inn'
                />
            </div>
            <button onClick={filter} className='targetfilter_button'  >
                {
                    state.Load ? "Loading ..." : `${t('filter')}`
                }
            </button>
            <img onClick={Unfilter} title={t('unfilter')} className='targetfilter_button_img' src={nofilterr} />
        </div>
    );
}

export default FilterWorkPlans;