import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const addProduct = () => {
  return {
    type: types.ADD_PRODUCT,
  };
};
const AddMarketingTool = () => {
  return {
    type: types.ADD_MARKETINGtOOL,
  };
};
const getProduct = (product) => {
  return {
    type: types.GET_PRODUCT,
    payload: product,
  };
};
const getMarketingTools = (marketingTool) => {
  return {
    type: types.GET_MARKETING_TOOLS,
    payload: marketingTool,
  };
};
const delProduct = () => {
  return {
    type: types.DELETE_PRODUCT,
  };
};
const delTool = () => {
  return {
    type: types.DELETE_TOOL,
  };
};
const editProduct = () => {
  return {
    type: types.EDIT_PRODUCT,
  };
};

export const loadProduct = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(getProduct(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};
export const loadMarketingTools = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/marketing-tools`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(getProduct(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const postProduct = (
  name,
  description,
  internal_reference,
  product_category_id,
  measurement_unit_id,
  color,
  product_type,
  image,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const Dataa = new FormData();
    Dataa.append("name", name);
    Dataa.append("description", description);
    Dataa.append("internal_reference", internal_reference);
    Dataa.append("product_category_id", product_category_id);
    Dataa.append("measurement_unit_id", measurement_unit_id);
    Dataa.append("color", color);
    Dataa.append("product_type", "medicine_samples");
    Dataa.append("image", image);
    axios
      .post(`${base_url}api/products`, Dataa, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(addProduct());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadProduct());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
export const postMarketingTools = (
  name,
  description,
  code,
  product_category_id,
  measurement_unit_id,
  color,
  product_type,
  image,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    const Dataa = new FormData();
    Dataa.append("name", name);
    Dataa.append("description", description);
    Dataa.append("code", code);
    // Dataa.append("product_category_id", product_category_id);
    Dataa.append("measurement_unit_id", measurement_unit_id);
    Dataa.append("color", color);
    Dataa.append("product_type", "marketing_tools");
    Dataa.append("image", image);
    axios
      .post(`${base_url}api/products`, Dataa, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(AddMarketingTool());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadProduct());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
export const deleteProduct = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delProduct());
          dispatch(loadProduct());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setdeletee(false);
        notifyError(err.response.data.message);
        setloadDel(false);
      });
  };
};
export const deleteTool = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/marketing-tools/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delTool());
          dispatch(loadProduct());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setdeletee(false);
        notifyError(err.response.data.message);
        setloadDel(false);
      });
  };
};

export const putProduct = (
  id,
  name,
  description,
  internal_reference,
  product_category_id,
  measurement_unit_id,
  color,
  product_type,
  image,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    console.log(name);
    let token = localStorage.getItem("tokenMiamed");
    const Dataa = new FormData();

    if (name !== "") Dataa.append("name", name);
    if (description !== "") Dataa.append("description", description);
    if (internal_reference !== "")
      Dataa.append("internal_reference", internal_reference);
    if (product_category_id !== "")
      Dataa.append("product_category_id", product_category_id);
    if (measurement_unit_id !== "")
      Dataa.append("measurement_unit_id", measurement_unit_id);
    if (color !== "") Dataa.append("color", color);
    if (product_type !== "") Dataa.append("product_type", product_type);
    if (image !== "") Dataa.append("image", image);

    axios
      .post(`${base_url}api/products/${id}`, Dataa, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(editProduct());
        if (res.data.status === "Success") {
          notifysuccess(res.data.message);
          dispatch(loadProduct());
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const loadFilterProduct = (
  measurement_unit_id,
  product_type,
  search_value,
  product_category_id,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(
        `${base_url}api/products?filter[measurement_unit_id]=${measurement_unit_id}&filter[product_type]=${product_type}&filter[search_value]=${search_value}&filter[product_category_id]=${product_category_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(getProduct(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
};
