import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const getRoles = (roles) => {
    return ({
        type: types.GET_ROLES,
        payload: roles
    })
}

const getPermission = (permission) => {
    return ({
        type: types.GET_PERMISSIONS,
        payload: permission
    })
}
const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}

const editPermission = () => {
    return ({
        type: types.EDIT_PERMISSIONS
    })
}

export const loadRoles = (navigate,setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/roles`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getRoles(res.data.data))
        }).catch((err) => {
            console.log(err)
            hasRout(err.response.status,navigate)
            if(err.message == "Network Error" || err.message){
                setopenInternet(true)
            }
        })
    }
}

export const loadPermissions = (id,navigate,setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/roles/${id}/edit`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res);
            dispatch(getPermission(res.data.data))
        }).catch((err) => {
            console.log(err)
            hasRout(err.response.status,navigate)
            if(err.message == "Network Error"){
                setopenInternet(true)
            }
        })
    }
}

export const emptyPermission = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_PERMISSIONS ))
    }
}

export const putPermission = (id,role_id,name,status,setloadStatusID) => {
    return function (dispatch) {
        setloadStatusID(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.put(`${base_url}api/roles/${role_id}/permissions?permission=${name}&status=${status}`,{}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        }).then((res) => {
            console.log(res);
            if (res.data.status == "Success"){
                notifysuccess(res.data.message);
                setloadStatusID(false)
                dispatch(editPermission())
                dispatch(loadPermissions(role_id))
            }
        }).catch((err) => {
            console.log(err)
            setloadStatusID(false)
            notifyError(err.response.data.message)
        })
    }
}


