import { useState } from "react";

export const VariableStatistics = () => {
    const [state, setState] = useState({
        startDate: '',
        endDate: '',
        target_type_id: '',
        report_type_id: '',
        Load: false,
        LoadExport: false,
        isFilter: false
    });
    const handleChangeStatistics = (value, variableName) => {
        console.log("value,",value)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setLoadExport = (value) => {
        setState((prevState) => ({
            ...prevState,
            LoadExport: value,
        }));
    };
    const setIsFilter= (value) => {
        setState((prevState) => ({
            ...prevState,
            isFilter: value,
        }));
    };
    return {
        ...state,
        state,
        setState,
        handleChangeStatistics,
        setLoad,
        setIsFilter,
        setLoadExport
    }
}
