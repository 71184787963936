import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const MultiSelectForReports = ({target_types, setReportsIds }) => {
  let { t } = useTranslation();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      borderColor: "#ccc",
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999",
      fontSize: "0.8em",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0",
      borderRadius: "4px",
      padding: "2px 4px",
    }),
  };

  const targetTypeOptions = target_types?.map((el) => {
    return {
      label: el.name,
      value: el.id,
    };
  });

  const onChange = (selectedOptions) => {
    const ids = selectedOptions.map((option) => option.value);
    console.log("onChange", ids);
    setReportsIds(ids);
  };

  return (
    <div>
      <Select
        options={targetTypeOptions}
        isMulti
        onChange={onChange}
        placeholder={t("Choose target types")}
        styles={customStyles}
      />
    </div>
  );
};

export default MultiSelectForReports;
