import { useState } from "react"
import { CheckFont } from "../General/GeneralComponent/CheckLang";

export const VariableRequest = () => {
    const [state, setState] = useState({
        title: "",
        body: null,
        endDate: null,
        Load: false
    })
    const setLoad = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const handleChangeRequest = (value, variableName) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    return {
        ...state,
        state,
        setState,
        handleChangeRequest,
        setLoad,
    }
}

export const ColumnRequest =(translate)=>{
    const columnsReq = [
        {
            name: <span className="color_spann">{translate('title')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.title)}}>{row['title']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('body')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.body)}}>{row['body']}</span>
            )
        }
    ]
    return {
        columnsReq
    }
}