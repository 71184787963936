import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadAllPermissions } from '../../Redux/Actions/ActionAuth';

function UsePermissions(setopenInternet) {
    const dispatch = useDispatch();
    const permissionss = useSelector(state => state.auth.permissionss)
    console.log("permissionss",permissionss);
    useEffect(() => {
        dispatch(loadAllPermissions(setopenInternet))
    }, []);
    return permissionss;
}

function hasPermissions(requiredPermissions, userPermissions) {
    const userPermissionNames = userPermissions.map(permission => permission.name);
    const requiredPermissionNames = requiredPermissions.map(permission => permission.name);
    const matchingPermissions = userPermissionNames.filter(name => requiredPermissionNames.includes(name));
    return requiredPermissionNames.every(name => matchingPermissions.includes(name));
}

export { UsePermissions, hasPermissions };