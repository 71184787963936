import React, { useState } from "react";
import useClickOutside from "../../useClickOutside";
import Add from "../General/Add";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { postWorkPlan } from "../../Redux/Actions/ActionWorkPlans";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import { VariableWorkPlans } from "./VariableWorkPlans";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";

function AddWorkPlan({ t, langmiamed, setOpen }) {
  let dispatch = useDispatch();
  const { medicalRep, loadingmedicalRep } = useSelectorCustom();
  const { LoadMedicalRepCustom } = FunctionsLoading();
  const { state, handleChangeWorkPlans, setLoad } = VariableWorkPlans();
  const [is_in_service, setis_in_service] = useState("1");
  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  const activee = () => {
    const newValue = is_in_service === "0" ? "1" : "0";
    setis_in_service(newValue);
  };
  const submit = (event) => {
    event.preventDefault();
    dispatch(
      postWorkPlan(
        state.note,
        state.startDate,
        state.endDate,
        is_in_service,
        state.days_duration,
        state.medical_rep_id,
        setLoad,setOpen
      )
    );
  };
  return (
    <div
      className={`add_target_group ${
        langmiamed == "ar" ? "add_target_group_ar" : "add_target_group_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <Add
        langmiamed={langmiamed}
        icon="las la-plus-circle"
        title={`${t("addWorkPlan")}`}
      />
      <form className="general_form">
        <div className="form_header">
          <i
            className={`las la-calendar ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("startDate")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="date"
          onChange={(e) => handleChangeWorkPlans(e.target.value, "startDate")}
        />
        <div className="form_header">
          <i
            className={`las la-calendar ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("endDate")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="date"
          onChange={(e) => handleChangeWorkPlans(e.target.value, "endDate")}
        />
        <div className="form_header">
          <i
            className={`las la-sort-numeric-up-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("dayDuration")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="number"
          onChange={(e) =>
            handleChangeWorkPlans(e.target.value, "days_duration")
          }
        />
        <div className="form_header">
          <i
            className={`las la-user ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("medicalRep")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingmedicalRep}
          array={medicalRep}
          setId={(value) => handleChangeWorkPlans(value, "medical_rep_id")}
          funb={LoadMedicalRepCustom}
        />
        <div className="form_header">
          <i
            className={`las la-comment ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("note")}</span>
        </div>
        <textarea
          onChange={(e) => handleChangeWorkPlans(e.target.value, "note")}
        />
        <div className="form_header">
          <i
            className={`las la-comment ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("in_service")}</span>
          <span className="require">*</span>
        </div>
        <Switch
          className="swith_add"
          onColor="#4285C5"
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={40}
          handleDiameter={15}
          checked={is_in_service === "1"}
          onChange={activee}
        />
        <br></br>
        <button onClick={submit}>
          {state.Load ? "Loading ..." : `${t("save")}`}
        </button>
      </form>
    </div>
  );
}

export default AddWorkPlan;
