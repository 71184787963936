import React from 'react'
import { CheckArabic, CheckFont } from '../General/GeneralComponent/CheckLang'

function OneProduct({ langmiamed, pp, handleEditProduct, warning, font_family }) {
    return (
        <div className='oneproduct' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
            {
                pp.media_urls.length != 0 ? (
                    <>
                        <div className='img_product' style={{ backgroundImage: ' linear-gradient(rgba(53, 37, 37, 0.0), rgba(0, 0, 0, 0.0)), url(' + pp.media_urls[0].url + ')' }} >
                            <span className='product_cat' style={{ fontFamily: CheckFont(pp.measurement_unit_name) }} >{pp.measurement_unit_name} </span>
                            <div className='action_target' >
                                <i className="las la-times" onClick={() => warning(pp)} ></i>
                                <i className="las la-pen" onClick={(event) => handleEditProduct(event, pp)}  ></i>
                            </div>
                        </div>
                        <div className='product_info'   >
                            <h5 style={{ color: pp.color || 'black', fontFamily: CheckArabic(pp.name,font_family) }}>{pp.name}</h5>
                            <span style={{ fontFamily: CheckFont(pp.description) }} >{pp.description}</span>
                            <span style={{ fontFamily: CheckFont(pp.internal_reference) }} >{pp.internal_reference}</span>
                            <span style={{ fontFamily: CheckFont(pp.category_name) }} >{pp.category_name} / <span style={{ fontFamily: CheckFont(pp.product_type) }}>{pp.product_type}</span></span>
                        </div>
                    </>
                ) : <>
                    <div className='img_product' style={{ backgroundColor: '#d3d0d0a7' }} >
                        <span className='product_cat' style={{ fontFamily: CheckFont(pp.measurement_unit_name) }} >{pp.measurement_unit_name}</span>
                        <div className='action_target' >
                            <i className="las la-times" onClick={() => warning(pp)} ></i>
                            <i className="las la-pen" onClick={(event) => handleEditProduct(event, pp)}  ></i>
                        </div>
                    </div>
                    <div className='product_info'>
                        <h5 style={{ color: pp.color || 'black', fontFamily: CheckArabic(pp.name,font_family) }}>{pp.name}</h5>
                        <span style={{ fontFamily: CheckFont(pp.description) }} >{pp.description}</span>
                        <span style={{ fontFamily: CheckFont(pp.internal_reference) }} >{pp.internal_reference}</span>
                        <span style={{ fontFamily: CheckFont(pp.category_name) }} >{pp.category_name} / <span style={{ fontFamily: CheckFont(pp.product_type) }}>{pp.product_type}</span></span>
                    </div>
                </>
            }

        </div>
    )
}

export default OneProduct