import { useState } from "react"

export const VariablePolitics = () => {
    const [state, setState] = useState({
        editorValuePrivacy: "",
        editorValueAbout: "",
        LoadPrivacy: false,
        LoadAbout: false
    })
    const setLoadPrivacy = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            LoadPrivacy: value,
        }));
    };
    const setLoadAbout = (value) => {
        console.log(value)
        setState((prevState) => ({
            ...prevState,
            LoadAbout: value,
        }));
    };
    const handleChangePolitics = (value, variableName) => {
        console.log(value)
        console.log(variableName)
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    return {
        ...state,
        state,
        setState,
        handleChangePolitics,
        setLoadPrivacy,
        setLoadAbout
    }
}