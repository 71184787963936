import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import useClickOutside from "../../../useClickOutside";
import { useTranslation } from "react-i18next";
import { CheckFont } from "./CheckLang";
import { useDispatch } from "react-redux";
import { FunctionsLoading } from "../../Main/Statistics/FunctionsLoading";
let selectedId = null;
function SelectWithSearch({
  array,
  load,
  typeCss,
  title,
  left,
  setId,
  funb,
  isEmpty,
  name,
  previousID,
  loadID,
  func,
  type,
}) {
  let langmiamed = localStorage.getItem("langmiamed");
  const { t } = useTranslation();
  const [open, setopen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [newArr, setnewArr] = useState([]);
  //   const [govId, setgovId] = useState(2);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(isEmpty);
    if (isEmpty === true) {
      setSearchValue("");
    }
    setSearchValue(name);
  }, [isEmpty, name]);

  useEffect(() => {
    const updatedArray = array?.map((item) => {
      return { id: item.id, name: t(item.name) };
    });
    setnewArr(updatedArray);
  }, [array]);

  useEffect(() => {
    setFiltered(newArr);
  }, [newArr]);
  let domNode = useClickOutside(() => {
    setopen(false);
  });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    const filtered = newArr.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFiltered(filtered);
  };
  const { LoadCitiesCustom, LoadGovernoratesCustom } = FunctionsLoading();

  const handleOptionClick = (item) => {
    if (type === "cities") {
      LoadGovernoratesCustom(item?.id);
    }
    setSearchValue(t(item.name));

    // if (previousID) {
    //   setId(previousID);
    // }

    setId(item.id);
    selectedId = item.id;
    setopen(false);
  };

  const openDropDown = (e) => {
    e.preventDefault();
    if (funb) {
      funb();
    }
    if (func) {
      func(loadID);
    }
    setopen(true);
  };
  return (
    <div className={left ? "div_creatable nomarginLeft" : "div_creatable"}>
      <div
        className={typeCss ? "view_creatable1 " : "view_creatable "}
        onClick={openDropDown}
      >
        <span style={{ fontFamily: CheckFont(searchValue) }}>
          {searchValue || `${title ? title : "Select"}`}
        </span>
        <i className="las la-angle-double-down icon_searchselect"></i>
      </div>
      {open && (
        <div
          className="select_creatable"
          ref={domNode}
          dir={langmiamed === "ar" ? "rtl" : "ltr"}
        >
          <input
            type="text"
            value={searchValue}
            onChange={handleInputChange}
            placeholder={`${t("search")} ...`}
            className="input_custm_select"
          />
          {load == false ? (
            filtered?.map((cc) => (
              <div
                className={`choose_creatable ${
                  searchValue === cc.name ? "active_creatable" : ""
                }`}
                key={cc.id}
                onClick={() => handleOptionClick(cc)}
              >
                <span style={{ fontFamily: CheckFont(t(cc.name)) }}>
                  {t(cc.name)}
                </span>
              </div>
            ))
          ) : (
            <div className="loadinselect">
              <ReactLoading
                type="spinningBubbles"
                color="#9699c2"
                height={"7%"}
                width={"7%"}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectWithSearch;
